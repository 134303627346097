<div class="flex flex-col items-start py-20 gap-10">
  <span class="flex flex-col w-full">
    <app-icon
      [icon]="'logo-picpay'"
      class="w-4/12 py-2 fill-gray-500"
    ></app-icon>
  </span>
  <div class="flex flex-col gap-2 items-start text-gray-900">
    <h1 class="font-bold text-3xl">Gerencie seus contratos</h1>
    <h2 class="font-normal leading-6 text-base">
      Aqui você consegue consultar e escolher formas de pagamento dos seus
      contratos de Empréstimo Pessoal, Empréstimo com FGTS e Empréstimo
      Consignado do <strong>PicPay Bank.</strong>
    </h2>
  </div>
  <form
    [formGroup]="sendcpf"
    (ngSubmit)="sendCPF()"
    *ngIf="existeUser === undefined"
    class="flex flex-col w-full gap-4 text-gray-900"
  >
    <div class="flex flex-col w-full">
      <p class="font-normal leading-6 text-base !mb-1">Acesse com seu CPF</p>
      <mat-form-field appearance="outline">
        <mat-label>CPF</mat-label>
        <input
          matInput
          inputmode="numeric"
          name="cpf"
          formControlName="cpf"
          maxlength="14"
          appMasks
        />
      </mat-form-field>

      <mat-error
        *ngIf="
          (sendcpf.touched || sendcpf.dirty) &&
          f.cpf.errors &&
          f.cpf.errors.required
        "
      >
        *Campo obrigatório
      </mat-error>
      <mat-error
        *ngIf="
          (sendcpf.touched || sendcpf.dirty) &&
          f.cpf.errors &&
          f.cpf.errors.cpfNotValid
        "
        >*CPF Inválido</mat-error
      >
      <mat-error
        *ngIf="
          (sendcpf.touched || sendcpf.dirty) &&
          f.cpf.errors &&
          f.cpf.errors.codeBlock
        "
        >*Código bloqueado, tente novamente em alguns minutos</mat-error
      >
      <mat-error *ngIf="isCpf">*CPF não existe</mat-error>
    </div>

    <app-button
      [type]="'submit'"
      [text]="'Acessar'"
      class="w-full py-3.5 h-full"
      [loading]="loadingBtn"
    ></app-button>
  </form>
  <form
    [formGroup]="sendSms"
    (ngSubmit)="submitSms()"
    *ngIf="existeUser && !isTimeOut"
    class="flex flex-col w-full gap-4 text-gray-900"
  >
    <div class="w-full">
      <div class="grid grid-cols-5 gap-4 m-0 items-center">
        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
          <input
            #firstCode
            matInput
            name="cod1"
            maxlength="1"
            formControlName="cod1"
            #txt1
            style="text-align: center"
            (keyup)="move(txt1, txt2)"
            (keypress)="numberOnly($event)"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
          <input
            matInput
            name="cod2"
            formControlName="cod2"
            maxlength="1"
            #txt2
            style="text-align: center"
            (keyup)="move(txt2, txt3)"
            (keydown.backspace)="moveBack(txt2, txt1)"
            (keypress)="numberOnly($event)"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
          <input
            matInput
            name="cod3"
            formControlName="cod3"
            maxlength="1"
            #txt3
            style="text-align: center"
            (keyup)="move(txt3, txt4)"
            (keydown.backspace)="moveBack(txt3, txt2)"
            (keypress)="numberOnly($event)"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
          <input
            matInput
            name="cod4"
            formControlName="cod4"
            maxlength="1"
            #txt4
            style="text-align: center"
            (keyup)="move(txt4, txt5)"
            (keydown.backspace)="moveBack(txt4, txt3)"
            (keypress)="numberOnly($event)"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="mat-form-field-no-padding">
          <input
            matInput
            name="cod5"
            formControlName="cod5"
            maxlength="1"
            #txt5
            (keydown.backspace)="moveBack(txt5, txt4)"
            (keypress)="numberOnly($event)"
          />
        </mat-form-field>
      </div>
      <p
        class="font-normal leading-6 text-xs"
        *ngIf="isValidCodSms && !isTimeOut"
      >
        Insira o código de 5 dígitos enviado para {{ userPhoneNumbers }}
      </p>
      <mat-error *ngIf="!isValidCodSms"
        >Código não reconhecido. Digite novamente.</mat-error
      >
    </div>

    <p>
      Código válido por
      <app-timer
        [timeInSeconds]="120"
        class="text-primary-700 font-bold"
      ></app-timer>
      minutos
    </p>

    <app-button
      [type]="'submit'"
      [text]="'Acessar'"
      class="w-full py-3.5 h-full"
      [loading]="loadingSmsBtn"
    ></app-button>
  </form>

  <div *ngIf="isTimeOut" class="gap-3 flex flex-col w-full">
    <p class="font-normal leading-6 text-sm text-critical-500">
      Clique no botão abaixo pra receber um novo código.
    </p>
    <app-button
      (click)="sendCPF()"
      [text]="'Reenviar código'"
      class="w-full py-3.5 h-full"
      [loading]="loadingSmsBtn"
      (keypress)="sendCPF()"
      (keydown)="sendCPF()"
      (keyup)="sendCPF()"
    ></app-button>
  </div>

  <span class="text-gray-900"
    >Se tiver dúvidas, acesse a
    <a
      class="text-primary-700 underline"
      href="https://picpay.com/canais-de-atendimento"
      >Central de Ajuda.</a
    ></span
  >
</div>