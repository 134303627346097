<button
  *ngIf="loading; else notLoading"
  class="py-2 px-4 items-center justify-center flex text-sm font-bold {{
    buttonVariants['loading']
  }} rounded-lg h-9 circle-loading {{ class }}"
>
  <mat-spinner color="primary" [diameter]="20"></mat-spinner>
</button>

<ng-template #notLoading>
  <button
    type="{{ buttonTypes[type] }}"
    class="py-2 px-4 text-white text-sm font-bold {{
      buttonVariants[variant]
    }} rounded-lg h-9 {{ class }}"
  >
    {{ text }}
  </button>
</ng-template>
