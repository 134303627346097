<div class="border border-gray-200 rounded-xl overflow-hidden mb-4 text-gray-900">
    <table class="w-full table-auto">
        <tr class="hidden md:grid grid-cols-4 bg-gray-50 text-base py-4 px-6 items-center">
            <th class="flex items-center gap-4 font-normal text-start">
                <app-checkbox
                    class="p-2 md:p-4"
                    (check)="handleSelectInstallment($event, null, true)"
                ></app-checkbox>
                Valor atualizado
            </th>
            <th class="font-normal text-start">Valor da parcela</th>
            <th class="font-normal text-center">Parcelas</th>
            <th class="font-normal text-center">Vencimento</th>
        </tr>
        <tr *ngFor="let item of openInstallments; let i = index" [ngClass]="{'disabled-table-row': isInstallmentDisabledForSelection(item.tranchCode)}"
            class="flex gap-4 md:grid md:grid-cols-4 items-center text-base md:text-xl font-bold p-4 md:p-6 w-full odd:bg-gray-50 even:bg-white">
            <td class="flex grow items-center gap-4">
                <app-checkbox
                    class="p-2 md:p-4"
                    [checked]="isInstallmentSelected(item.tranchCode)"
                    [disabled]="isInstallmentDisabled(item.tranchCode)"
                    (check)="handleSelectInstallment($event, item.tranchCode, false)"
                ></app-checkbox>
                <div class="text-lg md:text-xl">
                    <p class="text-xs font-normal md:hidden">Parcela {{item.installmentNumber}} de
                        {{numOfInstallments}}</p>
                    {{item.outstandingBalance | currency: "BRL"}}
                    <p class="text-xs font-normal md:hidden">Valor original: {{item.installmentAmount | currency:
                        "BRL"}}</p>
                </div>
            </td>
            <td class="hidden md:block">
                {{item.installmentAmount | currency: "BRL"}}
            </td>
            <td class="hidden md:block text-center">
                {{formatInstallmentCode(item.tranchCode)}} de {{numOfInstallments}}
            </td>
            <td class="text-right md:text-center">
                <p class="text-xs font-normal md:hidden">Vence em:</p>
                {{item.dueDate | brDate}}
            </td>
        </tr>
    </table>
</div>

<div *ngIf="selectedItems.length || mandatoryInstallments.length" class="sticky w-full flex justify-center items-center left-0 bottom-0 bg-white">
    <div class="w-full max-w-[1039px] flex justify-between items-center p-6">
        <div>
            <p class="text-[28px] font-bold">{{ totalValueToPay | currency: "BRL" }}</p>
            <p class="text-base">Total atualizado da antecipação</p>
        </div>

        <app-button [text]="'Pagar'" [loading]="loadingBtn" (click)="onClickPayment()" onKeyPress="onClickPayment()" tabindex="0"></app-button>
    </div>
</div>
