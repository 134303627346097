
import { Component, Input } from '@angular/core';
import { PartnerResponse } from 'src/app/shared/models/PartnerResponse.model';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent {
  @Input()
  public partner: PartnerResponse;
}
