<section class="section-fourth">
  <h1>Dúvidas frequentes</h1>
  <div class="container-expansion">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Para quais produtos consigo atendimento por este canal?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Aqui você tem acesso a informações de pagamentos dos produtos de
          <strong>Crédito Pessoal</strong>, <strong>PicPay Parcela</strong>,
          <strong>Empréstimo FGTS</strong> e <strong>Empréstimo Consignado</strong>
          do Picpay.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Quais os serviços disponíveis nessa plataforma para meus produtos
            contratados?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Nessa plataforma você poderá acompanhar o seu empréstimo, 
          emitir boleto ou código Pix para pagamento das parcelas, 
          consultar históricos e documentos relativos aos seus contratos.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Não tenho mais o telefone celular que cadastrei. O que devo fazer?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Pedimos que entre em contato com o nosso SAC pelo 0800 025 8000.</p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            O que devo fazer se não conseguir acessar as informações dos meus
            produtos contratados?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Pedimos que entre em contato com o nosso SAC pelo 0800 025 8000.</p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Não encontrei o serviço que preciso nesse canal. O que devo fazer?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Pedimos que entre em contato com o nosso SAC pelo 0800 025 8000.</p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>
