<mat-toolbar>
  <div class="profile-container">
    <div class="info-container">
      <img alt="Ícone de Perfil" class="imgIcon" src="../../../assets/images/icon-perfil.svg" />
      <div class="info">
        <span class="title" *ngIf="user?.name"
          >Olá, {{ getCapitalizeName(user.name) }}, boas-vindas!</span
        >
        <span class="desc">
          <span class="cpf">
            CPF: {{ user?.documentNumber || "***.***.***-**" | cpfCnpj }} |</span
          >
          <span class="legenda">
            Acompanhe a evolução dos seus contratos e faça pagamentos.
          </span>
        </span>
      </div>
    </div>
  </div>
</mat-toolbar>
