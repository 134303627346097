import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMasks]'
})
export class MasksDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    this.onInputChange(event.target.value);
  }

  onInputChange(value: string): void {
    let cpf = value.replace(/\D/g, '');
    cpf = cpf.match(/(\d{0,3})/g).join('.').slice(0, -1);

    if (cpf.length > 11) {
      let basic = cpf.slice(0, -2), digit = cpf.substr(-2).replace(/\D/g, '');
      if (basic[basic.length - 1] === '.') basic = basic.slice(0, -1);
      cpf = `${basic}-${digit}`;
    }
    this.ngControl.valueAccessor.writeValue(cpf);
  }
}
