import { ContractService } from './../../services/contract.service';
import { DialogAlertComponent } from './../dialog-alert/dialog-alert.component';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      title: string;
      actionYes: string;
      actionNo: string;
    },
    private dialogRef: MatDialogRef<DialogConfirmComponent>,
    public dialogAlert: MatDialog,
    public contractService: ContractService
  ) { }

  public cancel() {
    this.close(false);
  }

  public close(value) {
    this.dialogRef.close(value);
  }

  public openDialog(title: string, message: string): void {
    this.dialogAlert.open(DialogAlertComponent, {
      data: { title, message },
    });
  }

  async caseYes() {
    this.cancel();
    if (this.data.actionYes == 'confirmaEmailDeIR') {
      this.contractService.emitIncomeTaxStatement();
    } else if (this.data.actionYes == 'enviaFaturaPicPay') {
      this.contractService.requestInvoicePicpay();
    } else {
      this.contractService.requestInvoiceOriginal();
    }
  }

  caseNo() {
    this.cancel();
    if (this.data.actionNo == 'negaEmailDeIR') {
      this.openDialog(
        'Pedido cancelado',
        'Por gentileza entre em contato com o SAC 0800 025 8000, para que seja feita a atualização do seu emal, após a atualização, retome o processo por aqui.'
      );
    } else {
      this.openDialog(
        'Obrigado por nos visitar!',
        'Quando desejar sua fatura de cartão de crédito, basta confirmar a solicitação.'
      );
    }
  }
}
