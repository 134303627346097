<div class="container">
  <app-header
    [backgroundColor]="'#1A1B1A'"
    [logged]="true"
    [position]="'relative'"
  ></app-header>
  <app-profile></app-profile>
  <app-informations-contract></app-informations-contract>
  <app-footer style="width: 100%"></app-footer>
</div>
