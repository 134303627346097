import { Component, Input, OnInit } from '@angular/core';
import { Tranch } from 'src/app/shared/models/tranch.model';

@Component({
  selector: 'app-contract-card',
  templateUrl: './contract-card.component.html'
})
export class ContractCardComponent implements OnInit {
  @Input() variant: 'default' | 'small' = 'default';
  @Input() installment: Tranch;
  @Input() paymentFunc: Function;

  installmentStatusText: string;
  paymentDateText: string;
  buttonVariant: 'error' | 'default';
  loadingBtn: boolean = false;

  cardVariants = {
    'default': 'w-80 h-full rounded-xl justify-between',
    'small': 'w-full rounded-lg bg-gray-50'
  }

  ngOnInit() {
    this.setTexts();
  }

  setTexts() {
    this.installmentStatusText = this.isLateInstallment() ? 'Parcela em atraso' : 'Parcela em aberto';
    this.buttonVariant = this.isLateInstallment() ? 'error' : 'default';
    this.paymentDateText = this.isLateInstallment() ?
      `Atraso de <strong>${this.installment.overdueDays} dias</strong>` :
      `Vence em <strong>${this.formatDate(this.installment.dueDate)}</strong>`;
  }

  isLateInstallment() {
    return this.installment.situation === 'A';
  }

  onClick() {
    if (this.paymentFunc) {
      this.loadingBtn = true;
      this.paymentFunc(this.installment);
    }
  }

  formatDate(dateTime: string): string {
    let dateStr = dateTime;
    if (!dateStr.includes('T'))
      dateStr = dateStr.concat('T00:00:00');

    const date = new Date(dateStr);
    let formattedDate = date.toLocaleDateString('pt-BR', { day: '2-digit', month: 'short', year: 'numeric' });

    const parts = formattedDate.split(' de ');
    const day = parts[0];
    const month = parts[1].slice(0, 3);
    const year = parts[2];
    formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }
}
