
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  @Input()
  logged: boolean = false;

  @Input() backgroundColor: string;
  @Input() position: string;

  constructor(
    private clientService: ClientService) { }

  logout() {
    this.clientService.logout();
  }
}
