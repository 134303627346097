import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-interactive-card',
  templateUrl: './interactive-card.component.html'
})
export class InteractiveCardComponent implements OnInit {

  @Input() text!: string;
  @Input() icon!: string;

  constructor() { }

  ngOnInit() {
  }

}
