<h1>Ficou com alguma dúvida? Fale com a gente.</h1>
<div class="social-icons">
  <a rel="noopener noreferrer" href="https://www.facebook.com/BancoOriginal/" target="_blank">
    <img alt="Ícone Facebook" src="assets/images/icon-facebook.svg" />
  </a>
  <a rel="noopener noreferrer" href="https://www.instagram.com/bancooriginal/" target="_blank">
    <img alt="Ícone Instagram" src="assets/images/icon-instagram.svg" />
  </a>
  <a rel="noopener noreferrer" href="https://www.youtube.com/user/BancoOriginal" target="_blank">
    <img alt="Ícone Youtube" src="assets/images/icon-youtube.svg" />
  </a>
  <a rel="noopener noreferrer" href="https://twitter.com/bancooriginal" target="_blank">
    <img alt="Ícone Twitter" src="assets/images/icon-twitter.svg" />
  </a>
  <a rel="noopener noreferrer" href="https://www.linkedin.com/company/bancooriginal" target="_blank">
    <img alt="Ícone Linkedin" src="assets/images/icon-linkedin.svg" />
  </a>
</div>
