import { Payment } from './../../shared/models/payment.model';
import { ContractStatus } from './../../shared/models/contract-status.model';
import { Portion } from './../../shared/models/portion.model';
import { Contract } from './../../shared/models/contract.model';
import { catchError } from 'rxjs/operators';
import { Slip } from 'src/app/shared/models/slip.model';
import { ClientService } from 'src/app/services/client.service';
import { Router } from '@angular/router';
import { Subscription, throwError } from 'rxjs';
import { ContractService } from '../../services/contract.service';
import { DialogAlertComponent } from '../dialog-alert/dialog-alert.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PaymentRequest } from 'src/app/shared/models/paymentRequest.model';
import { arrayRange } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';

enum CONTRACT_STATUS {
  'A' = 1,  // Em atraso
  'A2' = 2, // Á Vencer
  'V' = 3,  // Vigente
  'C' = 4,  // Cancelado
  'L' = 5,  // Pago
  'R' = 6,  // Renegociado
  'D' = 7,  // Aditamento
}

const parseContractStatusA = ({situation, overdueDay}: Contract): string => (situation === 'A' && overdueDay <= 0)
? CONTRACT_STATUS['A2']
: CONTRACT_STATUS[situation];

const reverse = (acc: string, curr: string): number => {
  if (acc > curr) {
    return 1;
  }
  if (acc < curr) {
    return -1;
  }
  return 0;
};

const sortAsc = (a, b) => a - b;

@Component({
  selector: 'app-contract-accordion',
  templateUrl: './contract-accordion.component.html',
  styleUrls: ['./contract-accordion.component.scss'],
})
export class ContractAccordionComponent implements OnInit {
  subscription: Subscription;
  contracts: Contract[] = [];
  installments: Portion[] = [];
  selectedProductId?: string = null;
  paymentRequest: PaymentRequest;
  allSeletion: any = [];
  displayedColumns: string[] = [
    'selecao',
    'parcela',
    'value',
    'outstandingPrincipalBalance',
    'dueDate',
    'status',
  ];

  settled = new ContractStatus(
    'Liquidado',
    'assets/images/icon-liquidated.svg',
    'Ícone de liquidado',
    'status-liquidado'
  );

  arrears = new ContractStatus(
    'Em atraso',
    'assets/images/alert.svg',
    'Ícone de em atraso',
    'status-atraso'
  );

  reneg = new ContractStatus(
    'Renegociado',
    'assets/images/reneg.svg',
    'Ícone de reneg',
    'status-reneg'
  );

  active = new ContractStatus(
    'Vigente',
    'assets/images/active.svg',
    'Ícone de ativo',
    'status-ativo'
  );

  addition = new ContractStatus(
    'Aditamento',
    'assets/images/active.svg',
    'Ícone de ativo',
    'status-ativo'
  );

  check_all = false;
  buttonIsDisable = true;
  panelOpenState = false;
  totalSelectedInstallments: number = 0;

  dataSource = new MatTableDataSource<Contract>(this.contracts);
  selection = new SelectionModel<Portion>(true, []);

  constructor(
    private clientService: ClientService,
    public dialog: MatDialog,
    public contractService: ContractService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getListOfContract();
  }

  async closePanel() {
    this.selectedProductId = null;
    this.installments = [];
    this.disabledButton();
  }

  getListOfContract() {
    this.contractService.getListOfContract().subscribe((res) => {
      if (res) {
        const reordered = res.slice()
          .sort((a, b) => reverse(parseContractStatusA(a), parseContractStatusA(b)));

        this.contracts = reordered;
      }
    });
  }

  getInstallments(contractNumber) {
    this.contractService
      .getInstallmentsOfContract(contractNumber)
      .subscribe((contractDetail) => {
        if (contractDetail) {
          this.selectedProductId = contractDetail.productCode;
          const installmentsFull: Portion[] = contractDetail.tranches.map(
            (item) => ({
              ...item,
              enableable: false,
              checked: false,
            })
          );
          this.paymentRequest = new PaymentRequest(
            contractDetail.agency,
            contractDetail.company,
            contractNumber,
            '',
            contractDetail.productCode,
            contractDetail.merchant,
            contractDetail.store,
            []
          );
          this.installments = installmentsFull;
          this.startTable();
        }
      });
  }

  startTable() {
    let fgtsFirstEnableable = false;
    this.installments
      .filter((p) => p.situation === 'A' || p.situation === 'V')
      .forEach((p) => {
        if (this.isFGTS()) {
          if (p.overdueDays > 0) {
            p.enableable = true;
          } else if (p.overdueDays < 0 && !fgtsFirstEnableable) {
            p.enableable = true;
            fgtsFirstEnableable = true;
          } else {
            p.enableable = false;
          }
        } else if (this.isConsigned()) {
          p.enableable = false;
        } else {
          p.enableable = true;
        }
      });

    this.installments
      .filter((p) => p.situation === 'A' && !this.isFGTS())
      .forEach((p) => {
        p.checked = true;
      });

    if (this.isConsigned()) {
      for (var i = this.installments.length - 1; i >= 0; i--) {
        this.installments[i].enableable = !this.installments[i].checked && (this.installments[i].situation === 'V' || this.installments[i].situation === 'A');
        if (this.installments[i].situation === 'V' || this.installments[i].situation === 'A') {
          break;
        }
      }
    }

    this.check_all = this.installments.every((p) => p.checked);
    this.disabledButton();
    this.calculateInstallments();
  }

  getIfStatusContractEnd(contract) {
    if (this.isFGTS() && this.fgtsAnniversaryMonth(contract.firstDueDate)) {
      return true;
    }

    return contract.situation == 'L' || contract.situation == 'R';
  }

  // Validate select overdue
  // returns true valid, false invalid
  validOverduedInstallmentsToPay() {
    const installmentstToOverdueIds = this.installments
      .filter((p) => p.situation == 'A' && p.overdueDays > 0 && !this.isFGTS())
      .map((p) => Number(p.tranchCode))
      .slice()
      .sort(sortAsc);
    if (installmentstToOverdueIds.length <= 0) return true;

    const selectedToOverdue = this.installments
      .filter((p) => p.checked && p.overdueDays > 0)
      .map((p) => Number(p.tranchCode))
      .slice()
      .sort(sortAsc);
    // validate overdue empty
    if (selectedToOverdue.length <= 0) return false;

    // validate overdue equals 1 and no to be first
    const firstIdOverdue = installmentstToOverdueIds[0];
    if (
      selectedToOverdue.length >= 1 &&
      selectedToOverdue[0] != firstIdOverdue
    ) {
      return false;
    }
    const noHaveGaps = this.validateSelection(selectedToOverdue);
    return noHaveGaps;
  }

  // Validate select amortization
  // returns true valid, false invalid
  validAmortizationInstallmentsToPay() {
    const installmentsToAmortizationIds = this.installments
      .filter((p) => ['A', 'V'].includes(p.situation) && p.overdueDays <= 0 && !this.isFGTS())
      .map((p) => Number(p.tranchCode))
      .slice()
      .sort(sortAsc);
    if (installmentsToAmortizationIds.length <= 0) return true;

    const selectedToAmortization = this.installments
      .filter((p) => p.checked && p.overdueDays <= 0)
      .map((p) => Number(p.tranchCode))
      .slice()
      .sort(sortAsc);

    const selectedToOverdue = this.installments.filter(
      (p) => p.checked && p.overdueDays > 0
    );

    // validate amortization emty and selected overdue not empty
    if (selectedToAmortization.length <= 0 && selectedToOverdue.length > 0)
      return true;

    // validate amortization empty
    if (selectedToAmortization.length <= 0) return false;

    // validate amortization equals 1 and no to be first or last
    const firstSelection = selectedToAmortization[0];
    const firstIdAmortization = installmentsToAmortizationIds[0];
    const lastSelection =
      selectedToAmortization[
      selectedToAmortization.length > 0
        ? selectedToAmortization.length - 1
        : 0
      ];
    const lastIdAmortization =
      installmentsToAmortizationIds[
      installmentsToAmortizationIds.length > 0
        ? installmentsToAmortizationIds.length - 1
        : 0
      ];
    if (
      lastSelection != lastIdAmortization &&
      firstSelection != firstIdAmortization
    ) {
      return false;
    }

    const noHaveGaps = this.validateSelection(selectedToAmortization);
    return noHaveGaps;
  }

  paymentValidation(contractNumber) {
    if (
      this.validOverduedInstallmentsToPay() &&
      this.validAmortizationInstallmentsToPay() &&
      this.validatesAmortizationWhenHaveOverdued()
    ) {
      this.generatePayment(contractNumber);
    } else {
      this.openDialog(
        'Seleção inválida',
        'Se nao tiver parcelas em atraso e quiser antecipar deve respeitar a ordenação de cima para baixo ou debaixo para cima, respeitando a ordenação.'
      );
    }
  }

  validateSelection(validate) {
    return validate.every(
      (element: number, index: number) =>
        element + 1 === validate[index + 1] ||
        (!validate[index + 1] &&
          validate[index] ===
          validate[validate.length > 0 ? validate.length - 1 : 0])
    );
  }

  /**
   * Validate select amortization when have overdue
   * returns true valid, false invalid
   */
  validatesAmortizationWhenHaveOverdued() {
    const selectedToAmortization = this.installments.filter(
      (p) => p.checked && p.overdueDays <= 0 && !this.isFGTS()
    );
    if (selectedToAmortization.length <= 0) return true;

    const installmentstToOverdueIds = this.installments
      .filter((p) => p.situation == 'A' && p.overdueDays > 0)
      .map((p) => Number(p.tranchCode));
    if (installmentstToOverdueIds.length <= 0) return true;

    const selectedToOverdue = this.installments
      .filter((p) => p.checked && p.overdueDays > 0)
      .map((p) => Number(p.tranchCode));

    const everyToOverdue = installmentstToOverdueIds.every((p) =>
      selectedToOverdue.includes(p)
    );

    return everyToOverdue;
  }

  changeAll() {
    this.installments
      .filter((p) => p.situation != 'L')
      .forEach((p) => {
        if (this.isFGTS()) {
          if (p.overdueDays > 0 || this.fgtsAnniversaryMonth(p.dueDate)) {
            p.checked = false;
            p.enableable = false;
          } else {
            p.checked = this.check_all;
            p.enableable = this.check_all;
          }
        } else {
          p.checked = this.check_all;
          p.enableable = this.check_all;
        }
        return p;
      });

    let fgtsFirstEnableable = false;
    this.installments
      .filter((p) => p.situation === 'A' || p.situation === 'V')
      .forEach((p) => {
        if (this.isFGTS() && !this.check_all) {
          if (p.overdueDays > 0) {
            p.enableable = false;
          } else if (p.overdueDays < 0 && !fgtsFirstEnableable) {
            p.enableable = true;
            fgtsFirstEnableable = true;
          } else {
            p.enableable = false;
          }
        } else if (this.isConsigned() && !this.check_all && p.situation === 'A') {
          p.checked = true;
          p.enableable = false;
        }
      });

    if (this.isConsigned() && !this.check_all) {
      for (var i = this.installments.length - 1; i >= 0; i--) {
        this.installments[i].enableable = !this.installments[i].checked && (this.installments[i].situation === 'V' || this.installments[i].situation === 'A');
        if (this.installments[i].situation === 'V' || this.installments[i].situation === 'A') {
          break;
        }
      }
    }

    this.allSeletion = this.installments.filter((p) => p.situation != 'L' && p.checked);

    this.calculateInstallments();
    this.disabledButton();
  }

  removeZeroLeft(idInstallment: string) {
    return Number(idInstallment).toString();
  }

  openAlertDialog() {
    this.openDialog(
      'Detalhes do valor atualizado',
      'Você pode amortizar ou liquidar antecipadamente este ' +
      'empréstimo a valor presente. Vale ressaltar que o cálculo do saldo devedor da CCB considerará a taxa dos Juros Remuneratórios.'
    );
  }

  openDialog(title: string, message: string): void {
    this.dialog.open(DialogAlertComponent, {
      data: { title, message },
    });
  }

  colorBySituacao(element: any): string {
    switch (element.situation) {
      case 'L':
        return '#56B664';
      case 'A':
        return this.isFGTS() ? '#757575' : '#F5A623';
      case 'R':
        return '#21abcd';
      case 'V':
        return element.overdueDays < 0 ? '#757575' : '#5281E0';
      default:
        return '';
    }
  }

  changeCheck(event, installment: Portion) {
    if (this.isFGTS()) {
      this.changeCheckFGTS(event, installment);
    } else if (this.isConsigned()) {
      this.changeCheckConsigned(event, installment);
    } else {
      this.changeCheckDefault(event, installment);
    }
  }

  changeCheckDefault(event: MatCheckboxChange, installment: Portion) {
    let numinstallment: string = installment.tranchCode;
    this.allSeletion = [];

    this.selection.toggle(installment);
    this.installments
      .filter((p) => p.tranchCode === numinstallment && p.enableable === true)
      .forEach((p) => {
        p.checked = event.checked;
      });

    this.check_all =
      event.checked &&
      this.contracts.filter((p) => !p.checked && p.situation != 'L').length == 0;

    this.calculateInstallments();
    this.disabledButton();
  }

  changeCheckFGTS(event: MatCheckboxChange, installment: Portion) {
    let numinstallment: string = installment.tranchCode;
    this.allSeletion = [];

    if (installment.overdueDays > 0 || this.fgtsAnniversaryMonth(installment.dueDate)) {
      installment.checked = false;
      installment.enableable = false;
      setTimeout(() => {document.getElementById(event.source.id).classList.remove('mat-checkbox-checked')}, 500);

      this.openDialog(
        'O pagamento é descontado direto do FGTS',
        'No mês do seu aniversário, as parcelas são pagas automaticamente com o saldo do seu FGTS. \n\n' +
        'Perto desse período, a Caixa bloqueia a antecipação de qualquer parcela, ok?'
      );
    } else {
      this.selection.toggle(installment);
      this.installments
      .filter((p) => p.tranchCode === numinstallment && p.enableable === true)
      .forEach((p) => {
        p.checked = event.checked;
      });
    }


    const installmentIndex = this.installments.indexOf(installment);
    const installmentsLength = this.installments.length - 1;

    if (installmentIndex !== installmentsLength) {
      this.installments[installmentIndex + 1].enableable = true;
    }

    if (!event.checked) {
      arrayRange(installmentIndex + 1, installmentsLength).forEach((num) => {
        this.selection.toggle(this.installments[num]);
        this.installments[num].checked = false;
        this.installments[num].enableable = false;
      });
    }

    this.check_all = event.checked && this.contracts.filter((p) => !p.checked && p.situation != 'L').length == 0;

    this.calculateInstallments();
    this.disabledButton();
  }

  changeCheckConsigned(event: MatCheckboxChange, installment: Portion) {
    this.allSeletion = [];
    const installmentIndex = this.installments.indexOf(installment);

    if (installmentIndex > 0) {
      this.selection.toggle(installment);
      this.installments[installmentIndex].checked = event.checked;

      for (var i = installmentIndex - 1; i >= 0; i--) {
        this.installments[i].enableable = !this.installments[i].checked && this.installments[i].situation === 'V';
        if (this.installments[i].situation === 'V') {
          break;
        }
      }
    }

    if (!event.checked) {
      arrayRange(0, installmentIndex - 1).forEach((num) => {
        if (this.installments[num].overdueDays < 0) {
          this.installments[num].checked = false;
          this.installments[num].enableable = false;
        }
      });
    }

    this.check_all = event.checked && this.installments.length === this.installments.filter(p => p.checked).length;

    this.calculateInstallments();
    this.disabledButton();
  }

  calculateInstallments(): number {
    return (this.totalSelectedInstallments = this.validateCheckedInstallments()
      .map((p) => Number(p.outstandingBalance))
      .reduce((p1, p2) => p1 + p2, 0));
  }

  validateCheckedInstallments(): Portion[] {
    return this.installments
      .filter((p) => p.situation != 'L')
      .filter((p) => p.checked);
  }

  disabledButton() {
    let selectQuantity = this.contracts.filter(
      (p) => p.checked && p.situation != 'L'
    ).length;
    if (selectQuantity == 0) {
      this.buttonIsDisable = true;
    } else {
      this.buttonIsDisable = false;
    }
  }

  parseNameStatus(element: any) {
    switch (element.situation) {
      case 'A':
        return this.isFGTS() ? 'Vigente' : 'Em atraso';
      case 'R':
        return 'Renegociado';
      case 'V':
        return element.overdueDays < 0 ? 'À vencer' : 'Vigente';
      case 'L':
        return 'Pago';
      case 'C':
        return 'Cancelado';
      case 'D':
        return 'Aditamento';
      default:
        return '';
    }
  }

  parseStatusContract(contract: Contract): ContractStatus | null {
    switch (contract.situation) {
      case 'L':
        return this.settled;
      case 'A':
        return (contract.overdueDay <= 0 || environment.fgts.productIds.includes(contract.productCode)) ? this.active : this.arrears;
      case 'R':
        return this.reneg;
      case 'D':
        return this.addition;
      default:
        return null;
    }
  }

  rollAfterClose() {
    window.scroll({
      top: 300,
      left: 0,
      behavior: 'smooth',
    });
  }

  generatePayment(contractNumber) {
    let cpfCnpj = this.clientService.user.documentNumber;

    let body = new Slip(
      this.paymentRequest.agencyCode,
      this.paymentRequest.companyCode,
      contractNumber,
      cpfCnpj,
      this.paymentRequest.productCode,
      this.paymentRequest.shopkeeperCode,
      this.paymentRequest.storeCode,
      this.installments.filter((p) => p.checked === true).map((p) => p.tranchCode)
    );

    let paymentRequest = new PaymentRequest(
      body.agencyCode,
      body.companyCode,
      body.contractNumber,
      body.documentNumber,
      body.productCode,
      body.shopkeeperCode,
      body.storeCode,
      body.tranchesNumber
    );

    this.contractService._paymentRequest.next(paymentRequest);

    let payment = new Payment(
      contractNumber,
      this.validateCheckedInstallments().length,
      '',
      this.calculateInstallments()
    );

    this.contractService.setPayments(payment);

    this.rollAfterClose();

    this.contractService
      .getPix()
      .pipe(
        catchError((err) => {
          if (err.error.status == 412) {
            this.openDialog('Não foi possivel prosseguir', err.error.error);
          } else {
            this.closePanel();
            this.router.navigate(['/pagamentos']);
          }
          return throwError(err);
        })
      )
      .subscribe((result) => {
        this.closePanel();
        this.contractService._paymentPix.next(result);
        this.router.navigate(['/pagamentos']);
      });
  }

  isConsigned() {
    return environment.consigned.productIds.includes(this.selectedProductId);
  }

  isFGTS() {
    return environment.fgts.productIds.includes(this.selectedProductId);
  }

  fgtsAnniversaryMonth(dueDate: string) {
    const currentDate = new Date().getTime();
    const installmentDate = new Date(Date.parse(dueDate)).getTime();
    const diffDates = (installmentDate - currentDate) / (1000 * 3600 * 24);

    return Math.floor(diffDates) <= environment.fgts.daysToBirthdayMonth;
  }
}
