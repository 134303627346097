<div class="flex flex-col gap-2 p-4 border text-gray-900 {{cardVariants[variant]}}">
    <h3 *ngIf="variant == 'default'" class="!text-xl !font-bold !m-0">{{installment.productDescription || ''}}</h3>

    <div class="flex justify-between items-center">
        <div>
            <p class="text-xs leading-5">{{installmentStatusText}}</p>
            <h3 class="!text-xl !font-bold !m-0 {{variant == 'default' && isLateInstallment() && 'text-critical'}}">
                {{ installment.outstandingBalance | currency : "BRL" }}
            </h3>
            <p class="text-sm" [innerHTML]="paymentDateText"></p>
        </div>

        <app-button [text]="'Pagar'" [loading]="loadingBtn" [variant]="buttonVariant" (click)="onClick()" onKeyPress="onClick()" tabindex="0"></app-button>
    </div>
</div>