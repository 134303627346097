<div class="dialog">
  <div class="header">
    <h2 matDialogTitle>Redirecionamento</h2>
    <mat-dialog-actions>
      <button mat-button mat-dialog-close class="matDialogClose">
        Fechar
        <img alt="Ícone Fechar" src="../../../assets/images/icon-close.svg" />
      </button>
    </mat-dialog-actions>
  </div>
  <hr />
  <mat-dialog-content>
    Para fazer uma renegociação das suas dívidas
  </mat-dialog-content>
  <div mat-dialog-actions>
    <a href="https://renegociacao.picpay.com?redirect=nc">
      <button mat-button>Acessar</button>
    </a>
  </div>
</div>
