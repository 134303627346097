export const listOfOptions = [
  {
    descriptionImg: 'Ícone liquidação',
    img: '../../../assets/images/icon-termos.svg',
    title: 'Empréstimos',
    descriptionOption:
      'Consulte e pague suas parcelas. Se desejar, você podem antecipar ou quitar o seu contrato.',
    method: 'LISTAR PARCELAS',
    able: true,
  },
  {
    descriptionImg: 'Ícone consulta informações do contrato',
    img: '../../../assets/images/icon-registro.svg',
    title: 'Informação do contrato',
    descriptionOption:
      'Consulte a Cédula de Crédito Bancária dos seus contratos.',
    method: 'CONSULTA INFORMACOES',
    able: true,
  },
  {
    descriptionImg: 'Ícone Documento Descritivo do Crédito',
    img: '../../../assets/images/icon-termos.svg',
    title: 'Documento Descritivo do Crédito',
    descriptionOption:
      'Consulte os detalhes da evolução do pagamento dos seus empréstimos e acompanhe o percentual de juros, tarifas e encargos que compõem cada parcela.',
    method: 'CONSULTA DDC',
    able: true,
  },
  {
    descriptionImg: 'Ícone extrato',
    img: '../../../assets/images/icon-termos.svg',
    title: 'Extrato anual de empréstimos parcelados',
    descriptionOption:
      'Consulte o extrato anual com os detalhes completos do pagamento do seu empréstimo. \n Esse documento serve de base pra sua declaração do Imposto de Renda.',
    method: 'EXTRATO ANUAL',
    able: true,
  },
];
