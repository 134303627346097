<div class="flex w-screen h-screen">
    <div class="flex flex-row w-full justify-between h-full">
        <div class="flex w-full lg:w-2/4 justify-center h-full">
            <div class="flex w-full max-w-lg p-4 lg:pl-20 h-full items-start lg:items-center">
                <ng-content></ng-content>
            </div>
        </div>
        <div class="hidden lg:block w-2/4 h-screen overflow-hidden">
            <img class="h-full w-full object-cover object-right-top" [src]="img.path" [alt]="img.description"/>
        </div>
    </div>
</div>