import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brDate'
})
export class DatePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    if (!value) {
      return '';
    }

    let dateStr = value;
    if (!dateStr.includes('T'))
      dateStr = dateStr.concat('T00:00:00');

    const date = new Date(dateStr);
    let formattedDate = date.toLocaleDateString('pt-BR', { day: '2-digit', month: 'short', year: 'numeric' });

    const parts = formattedDate.split(' de ');
    const day = parts[0];
    const month = parts[1].slice(0, 3);
    const year = parts[2];
    formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

}
