import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-channels-section',
  templateUrl: './support-channels-section.component.html'
})
export class SupportChannelsSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  channels = [
    {
      title: 'Telefone',
      icon: 'phone',
      message: 'SAC: 08000 258 000',
      supportText: 'Todos os dias, 24h',
      url: ''
    },
    {
      title: 'Outros canais',
      icon: 'comment-alt-exclamation',
      message: 'Site institucional',
      supportText: 'www.picpay.com',
      url: 'https://picpay.com/'
    }
  ]

}
