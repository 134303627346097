<section class="central">
  <h1>Central de atendimento</h1>
  <p>
    Atendimento das 7h às 20h.
  </p>

  <div class="grid-contact">
    <div class="text-contact">
      <h2>0800 025 8000</h2>
      <p>Renegociação de Crédito Pessoal em atraso</p>
    </div>
    <div class="text-contact">
      <h2>4004 0800</h2>
      <p>Informações sobre Cartão de Crédito em capitais e regiões metropolitanas</p>
    </div>
    <div class="text-contact">
      <h2>0300 777 0800</h2>
      <p>Informações sobre Cartão de Crédito em outras localidades</p>
    </div>
    <div class="text-contact">
      <h2>0800 025 8000</h2>
      <p>Demais informações e SAC</p>
    </div>
  </div>
</section>
