import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientService } from '../services/client.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: ClientService, private router: Router) {}
  canActivate(): Observable<boolean> | boolean {
    if (this.auth.isAuthenticad()) {
      return true;
    }

    this.router.navigate(['/']);

    return false;
  }
}
