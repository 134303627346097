import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerStateService {
  private isTimeOutSource = new BehaviorSubject<boolean>(false);
  isTimeOut$ = this.isTimeOutSource.asObservable();

  constructor() { }

  setTimeOutState(isTimeOut: boolean) {
    this.isTimeOutSource.next(isTimeOut);
  }
}