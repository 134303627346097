<app-center-column-layout>
  <section class="flex flex-col gap-7">
    <div>
      <app-breadcrumb>
        <app-breadcrumb-item class="flex" [url]="'/meus-emprestimos'">
          <span class="flex items-center gap-2">
            <app-icon
              [icon]="'hand-money'"
              class="w-4 h-4 fill-gray-800"
            ></app-icon>
            Meus empréstimos
          </span>
        </app-breadcrumb-item>
        <app-breadcrumb-item [url]="'/emprestimo/' + contractId">
          Contrato
        </app-breadcrumb-item>
        <app-breadcrumb-item>Pagar</app-breadcrumb-item>
      </app-breadcrumb>
    </div>

    <h2 class="!text-[32px] !leading-[41px] !font-bold !m-0 text-gray-900">
      Pagar
    </h2>

    <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
      <div class="flex flex-col gap-4">
        <app-payment
          [payment]="payment"
          [paymentLoading]="loadingPaymentInfo"
        ></app-payment>

        <app-button
          *ngIf="billetBtnEnabled && !paymentByPix"
          class="w-full"
          [class]="'h-auto py-[14px]'"
          [text]="'Gerar boleto'"
          [loading]="loadingBtn"
          (click)="generateBillet()"
          onKeyPress="generateBillet()"
          tabindex="0"
        ></app-button>
      </div>

      <div class="flex flex-col w-full gap-4">
        <app-pix-payment
          *ngIf="paymentByPix"
          data-testid="pix-component"
          [pixData]="pix"
          [paymentLoading]="loadingPix"
        ></app-pix-payment>

        <section *ngIf="paymentUnavailable">
          <div class="flex flex-col gap-4 w-full">
            <div class="flex flex-col p-6 border rounded-xl gap-6">
              <h3 class="text-[1.8rem] text-center font-bold !m-0 text-gray-900">
                Opa! Algo não deu certo
              </h3>

              <div class="flex flex-col justify-between items-center gap-6">
                <div class="flex flex-col w-full">
                  <p class="text-base text-center leading-5 font-normal text-gray-900">
                    Nosso sistema está indisponível no momento e não conseguimos
                    concluir a ação. Vamos tentar novamente?
                  </p>
                </div>
              </div>

              <div class="grid grid-cols-2">
                <app-button [text]="'Tentar novamente'" [routerLink]="['/emprestimo', contractId]" class="w-full"></app-button>
                <app-button [text]="'Voltar para página inicial'" [routerLink]="['/meus-emprestimos']"
                  class="w-full bg-white !text-primary-700"></app-button>
              </div>
            </div>
          </div>
        </section>

        <app-billet-payment
          *ngIf="paymentByBillet"
          data-testid="billet-component"
          [billetData]="billet"
          [paymentLoading]="loadingBillet"
        ></app-billet-payment>

        <app-button
          *ngIf="billetBtnEnabled && paymentByPix"
          class="w-full"
          [class]="'h-auto py-[14px]'"
          [text]="'Gerar boleto'"
          [loading]="loadingBtn"
          (click)="generateBillet()"
          onKeyPress="generateBillet()"
          tabindex="0"
        ></app-button>
      </div>
    </div>
  </section>
</app-center-column-layout>