import { CodeInformations } from './CodeInformations.model';

export class Slip extends CodeInformations {
  constructor(
    agencyCode: string,
    companyCode: string,
    contractNumber: string,
    documentNumber: string,
    productCode: string,
    shopkeeperCode: string,
    storeCode: string,
    public tranchesNumber: string[]
  ) {
    super(
      agencyCode,
      companyCode,
      contractNumber,
      documentNumber,
      productCode,
      shopkeeperCode,
      storeCode
    );
  }
}
