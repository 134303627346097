import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-installment-table-section',
  templateUrl: './installment-table-section.component.html'
})
export class InstallmentTableSectionComponent implements OnInit {
  @Input() installments = [];
  @Input() paymentFunc: Function;
  @Input() daysToBirthdayMonth: number;
  @Input() canAdvanceInstallments = true;

  selectedChip = 'open';
  inAdvancementMode = false;
  modalOpen = false;

  ngOnInit() {
    this.openInstallments.length ? this.selectedChip = 'open' : this.selectedChip = 'paid';
  }

  selectChip(value: string) {
    this.selectedChip = value;
  }

  get filteredItems() {
    return this.installments.filter(item => {
      if (this.selectedChip == 'open') return !this.isPaidInstallment(item);
      else return this.isPaidInstallment(item);
    });
  }

  isPaidInstallment(installment) {
    return installment.situation === 'L';
  }

  get openInstallments() {
    return this.installments.filter(item => item.situation !== 'L');
  }

  handleAdvancementMode() {
    if (this.selectedChip == 'open') {
      if (this.canAdvanceInstallments)
        this.inAdvancementMode = true
      else this.openModal();
    }
  }

  formatInstallmentCode(code: string) {
    return Number(code).toString();
  }

  hasActiveAndPaidInstallments() {
    return this.openInstallments.length > 0 && this.openInstallments.length < this.installments.length;
  }

  closeModal(): void {
    this.modalOpen = false;
  }

  openModal() {
    this.modalOpen = true;
  }
}
