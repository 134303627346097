import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientService } from 'src/app/services/client.service';
import { ContractService } from 'src/app/services/contract.service';
import { Contract } from 'src/app/shared/models/contract.model';
import { Payment } from 'src/app/shared/models/payment.model';
import { PaymentRequest } from 'src/app/shared/models/paymentRequest.model';
import { Tranch } from 'src/app/shared/models/tranch.model';


const CONTRACT_STATUS_CATEGORIES: { [key: string]: string[] } = {
  'active': [
    'A',  // Em atraso
    'A2', // Á Vencer
    'V'   // Vigente
  ],
  'finalized': [
    'C', // Cancelado
    'L', // Pago
    'R', // Renegociado
    'D'  // Aditamento
  ]
};

const sortByDate = (a: string, b: string): number => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  return dateA.getTime() - dateB.getTime();
};

@Component({
  selector: 'app-contracts-hub',
  templateUrl: './contracts-hub.component.html'
})
export class ContractsHubComponent implements OnInit {
  contracts: Contract[] = [];
  nextInstallments: Tranch[] = [];
  user = this.clientService.user;

  loadingNextInstallments = true;
  loadingContractList = true;
  selectedChip = 'active';

  constructor(
    public contractService: ContractService,
    public clientService: ClientService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getListOfContracts();
    this.getNextInstallments();
  }

  getListOfContracts() {
    this.contractService.getListOfContract().subscribe((res) => {
      if (res) {
        const reordered = res.slice().sort((a: Contract, b: Contract) => sortByDate(a.issueDate, b.issueDate));

        this.contracts = reordered;
      }

      this.loadingContractList = false;
    });
  }

  getNextInstallments() {
    this.contractService.getNextInstallments().subscribe((res) => {
      if (res) {
        this.nextInstallments = res.sort((a: Tranch, b: Tranch) => sortByDate(a.dueDate, b.dueDate));
      }

      this.loadingNextInstallments = false;
    });
  }

  generatePayment(installment: Tranch) {
    this.contractService.getInstallmentsOfContract(installment.contractNumber)
      .subscribe((contract) => {
        if (contract) {
          const paymentRequest = new PaymentRequest(
            contract.agency,
            contract.company,
            installment.contractNumber,
            this.user.documentNumber,
            contract.productCode,
            contract.merchant,
            contract.store,
            [installment.tranchCode]
          );

          this.contractService._paymentRequest.next(paymentRequest);

          const currentDate = (new Date()).toISOString();
          let payment = new Payment(
            installment.contractNumber,
            1,
            currentDate,
            installment.outstandingBalance
          );

          this.contractService.setPayments(payment);
          this.router.navigate(['/pagamentos']);
        }
      });
  }

  goToContract(contractNumber: string) {
    this.router.navigate(['emprestimo', contractNumber]);
  }

  selectChip(value: string) {
    this.selectedChip = value;
  }

  get filteredContracts() {
    return this.contracts.filter(item => CONTRACT_STATUS_CATEGORIES[this.selectedChip].includes(item.situation));
  }

  scrollToNext() {
    const container = document.getElementById('cardsContainer');
    container.scrollLeft += 320;
  }

  onClickContract(event: KeyboardEvent, contractNumber: string): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.goToContract(contractNumber);
    }
  }

  hasActiveAndFinalizedContracts() {
    let finalizedContracts = this.contracts.filter(item => CONTRACT_STATUS_CATEGORIES['finalized'].includes(item.situation));

    return finalizedContracts.length > 0 && finalizedContracts.length < this.contracts.length;
  }
}
