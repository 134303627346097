import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-wait',
  templateUrl: './loading-wait.component.html'
})

export class LoadingWaitComponent implements OnInit {
  loadingWaitContracts: boolean = true;
  public lottieConfig: Object;

  constructor() {
    this.lottieConfig = {
      path: '../../assets/loading.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true,
    };
  }

  ngOnInit() {}
};

