<section *ngIf="!inAdvancementMode" class="flex flex-col gap-6 text-gray-900">
  <h2 class="!text-[28px] !leading-[34px] !font-bold !m-0">
    Todas as parcelas
  </h2>

  <app-chip-group *ngIf="hasActiveAndPaidInstallments()">
    <app-chip
      [value]="'open'"
      [label]="'Em aberto'"
      (selected)="selectChip($event)"
    ></app-chip>
    <app-chip
      [value]="'paid'"
      [label]="'Pagas'"
      (selected)="selectChip($event)"
    ></app-chip>
  </app-chip-group>

  <div class="border border-gray-200 rounded-xl overflow-hidden">
    <table class="w-full table-auto">
      <tr class="hidden md:grid grid-cols-3 bg-gray-50 text-base py-4 px-6">
        <th class="font-normal text-start">Valor da parcela</th>
        <th class="font-normal text-center">Parcelas</th>
        <th class="font-normal text-center">Vencimento</th>
      </tr>
      <tr
        *ngFor="let item of filteredItems; let i = index"
        (click)="handleAdvancementMode()"
        onKeyPress="handleKeyPress($event)"
        onKeyDown="handleKeyDown($event)"
        onKeyUp="handleKeyUp($event)"
        class="grid grid-cols-2 md:grid-cols-3 items-center text-base md:text-xl font-bold p-4 md:p-6 w-full odd:bg-gray-50 even:bg-white"
        [class.cursor-pointer]="!isPaidInstallment(item)"
      >
        <td class="text-lg md:text-xl">
          <p class="text-xs font-normal md:hidden">
            Parcela {{ formatInstallmentCode(item.tranchCode) }} de
            {{ installments.length }}
          </p>
          {{ item.installmentAmount | currency : 'BRL' }}
        </td>
        <td class="hidden md:block text-center">
          {{ formatInstallmentCode(item.tranchCode) }} de
          {{ installments.length }}
        </td>
        <td class="text-right md:text-center">
          <p class="text-xs font-normal md:hidden">Vence em:</p>
          {{ item.dueDate | brDate }}
        </td>
      </tr>
    </table>
  </div>
</section>

<section *ngIf="inAdvancementMode" class="flex flex-col gap-6">
  <div class="flex flex-col gap-4">
    <h2 class="!text-[28px] !leading-[34px] !font-bold !m-0">Antecipação</h2>

    <p class="text-base">
      Selecione as parcelas. Elas já estão com os valores atualizados pro
      pagamento no dia de hoje.
    </p>
  </div>

  <app-installment-advance-table
    [openInstallments]="openInstallments"
    [numOfInstallments]="installments.length"
    [paymentFunc]="paymentFunc"
    [daysToBirthdayMonth]="daysToBirthdayMonth"
  ></app-installment-advance-table>
</section>

<app-modal *ngIf="modalOpen" (closeFunction)="closeModal()">
  <h3 class="font-bold text-2xl">O pagamento é descontado direto do seu FGTS</h3>

  <p class="text-base">
    No mês do seu aniversário, as parcelas são pagas automaticamente com o saldo do seu FGTS.
  </p>
  <p class="text-base">
    Perto desse período, a Caixa bloqueia a antecipação de qualquer parcela, ok?
  </p>
</app-modal>
