<div class="section-contracts">
  <div class="container-contracts">
    <p><a [routerLink]="['/meus-emprestimos']"> ← Voltar para opções </a></p>
    <h1>Empréstimos</h1>

    <div class="container-cards">
      <app-contract-accordion></app-contract-accordion>
    </div>
  </div>
</div>
