<app-center-column-layout class="text-gray-900">
  <section class="space-y-6">
    <app-breadcrumb>
      <app-breadcrumb-item class="flex" [url]="'/meus-emprestimos'">
        <span class="flex items-center gap-2">
          <app-icon [icon]="'hand-money'" class="w-4 h-4 fill-gray-800"></app-icon>
          Meus empréstimos
        </span>
      </app-breadcrumb-item>
      <app-breadcrumb-item>Contrato</app-breadcrumb-item>
    </app-breadcrumb>

    <div class="flex flex-col md:flex-row justify-between items-start gap-6 text-gray-900">
      <div class="flex flex-col gap-8 w-full md:max-w-[424px]">
        <h1 *ngIf="!loadingContract; else titleSkeleton" class="!text-[28px] !leading-[34px] !font-bold !m-0">
          {{contract.productDescription}}
        </h1>

        <ng-template #titleSkeleton>
          <app-skeleton class="w-full h-9 block rounded-md overflow-hidden"></app-skeleton>
        </ng-template>

        <div class="w-full flex flex-col gap-4">
          <div class="w-full flex justify-between">
            <div class="space-y-1">
              <p class="text-sm">Você pagou</p>
              <p *ngIf="!loadingContract; else textSkeleton" class="text-base font-bold">
                {{paidInstallments}} de {{installmentsNumber}} parcelas
              </p>
            </div>

            <div class="space-y-1 text-right">
              <p class="text-sm">Ainda falta pagar</p>
              <p *ngIf="!loadingContract; else textSkeleton" class="text-base font-bold">
                {{contract.currentBalance | currency: "BRL"}}
              </p>
            </div>

            <ng-template #textSkeleton>
              <app-skeleton class="w-full h-6 block rounded overflow-hidden"></app-skeleton>
            </ng-template>
          </div>

          <app-progress-bar [max]="installmentsNumber" [value]="paidInstallments"></app-progress-bar>
        </div>
      </div>

      <div class="flex flex-col gap-4 w-full md:max-w-[512px]">
        <app-contract-card *ngIf="contract?.nextInstallment" [variant]="'small'" [installment]="contract.nextInstallment"
          [paymentFunc]="generateInstallmentPayment.bind(this)"></app-contract-card>

        <div class="grid grid-cols-3 gap-4">
          <app-interactive-card [text]="'Todas as parcelas'" [icon]="'receipt-alt'"
            (click)="setActiveSection('installments')" tabindex="0"
            onKeyPress="setActiveSection('installments')"></app-interactive-card>
          <app-interactive-card [text]="'Detalhes do contrato'" [icon]="'file-alt'"
            (click)="setActiveSection('details')" tabindex="0"
            onKeyPress="setActiveSection('installments')"></app-interactive-card>
          <app-interactive-card [text]="'Canais de atendimento'" [icon]="'feedback-info-mono'"
            (click)="setActiveSection('support')" tabindex="0"
            onKeyPress="setActiveSection('installments')"></app-interactive-card>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="!loadingContract; else loadingSpinner">
    <app-installment-table-section *ngIf="activeSection == 'installments'" [installments]="contract.tranches"
      [paymentFunc]="generatePayment.bind(this)" [canAdvanceInstallments]="contract.anticipationEnabled">
    </app-installment-table-section>
    <app-contract-details-section *ngIf="activeSection == 'details'" [contract]="contract" [ccbDocument]="ccbDocument"
      [ddcDocument]="ddcDocument" [aditiveDocument]="aditiveDocument">
    </app-contract-details-section>
    <app-support-channels-section *ngIf="activeSection == 'support'"></app-support-channels-section>
  </section>

  <ng-template #loadingSpinner>
    <div class="m-auto mt-28 circle-loading">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  </ng-template>
</app-center-column-layout>