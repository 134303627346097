import { Message } from '../../shared/models/message.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.scss'],
})
export class MessageViewComponent implements OnInit {
  message: Message;
  status: string;

  constructor(private router: ActivatedRoute) {
    this.status = this.router.snapshot.paramMap.get('status');
  }

  existLink() {
    return (this.message.link || '').length > 0;
  }

  ngOnInit() {
    this.message = new Message(
      'Opa! Algo deu errado',
      'Não conseguimos concluir a sua solicitação, mas você pode tentar novamente.',
    );

    if (this.status === '404') {
      this.message.link = '/';
    }
    else {
      this.message.link = '/meus-emprestimos';
    }
  }
}
