export class CodeInformations {
  constructor(
    public agencyCode: string,
    public companyCode: string,
    public contractNumber: string,
    public documentNumber: string,
    public productCode: string,
    public shopkeeperCode: string,
    public storeCode: string
  ) {}
}
