<div class="dialog">
  <div class="header">
    <h1 matDialogTitle>{{ data.title }}</h1>
    <mat-dialog-actions>
      <button mat-button mat-dialog-close class="matDialogClose">
        Fechar
        <img alt="Ícone Fechar" src="../../../assets/images/icon-close.svg" />
      </button>
    </mat-dialog-actions>
  </div>
  <hr />
  <mat-dialog-content>
    {{ data.message }}
  </mat-dialog-content>
  <div mat-dialog-actions>
    <div class="group-toggle">
      <mat-button-toggle-group class="button-toggle-group">
        <mat-button-toggle
          class="toggle"
          *ngFor="let item of data.receivedList"
          [value]="item"
          (click)="onSelect(item)"
        >
          {{ item }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <p (click)="centralAlert()">Meu número não está aqui!</p>
    <button mat-button (click)="onConfirm()" class="ok" [disabled]="disabled">OK</button>
  </div>
</div>
