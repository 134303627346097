import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { ContractService } from 'src/app/services/contract.service';
import { CCBDocumentInformations } from 'src/app/shared/models/ccbDocumentInformations.model';
import { Contract } from 'src/app/shared/models/contract.model';
import { DDCDocumentInformations } from 'src/app/shared/models/ddcDocumentInformations.model';
import { Payment } from 'src/app/shared/models/payment.model';
import { PaymentRequest } from 'src/app/shared/models/paymentRequest.model';
import { Slip } from 'src/app/shared/models/slip.model';
import { Tranch } from 'src/app/shared/models/tranch.model';

@Component({
  selector: 'app-contract-page',
  templateUrl: './contract-page.component.html'
})
export class ContractPageComponent implements OnInit {
  contract?: Contract = null;
  ccbDocument: CCBDocumentInformations;
  aditiveDocument: any;
  ddcDocument: DDCDocumentInformations;
  paymentRequest: PaymentRequest;

  activeSection = 'installments';
  loadingContract = true;
  user = this.clientService.user;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public contractService: ContractService,
    public clientService: ClientService,
  ) { }

  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    const contractNumber = routeParams.get('contractNumber');
    this.getContractData(contractNumber);
  }

  getContractData(contractNumber: string) {
    this.contractService.getInstallmentsOfContract(contractNumber)
      .subscribe((contract) => {
        if (contract) {
          this.contract = contract;
          this.paymentRequest = new PaymentRequest(
            contract.agency,
            contract.company,
            contractNumber,
            '',
            contract.productCode,
            contract.merchant,
            contract.store,
            []
          );

          this.getCCB();
          this.getDDC();

          if (this.contract.documentNumber !== this.user.documentNumber)
            this.router.navigate(['/meus-emprestimos']);
        }
        this.loadingContract = false;
      });
  }

  getCCB() {
    this.contractService
      .getListOfDocuments(this.contract.contractNumber, 'CCB')
      .subscribe((array) => {
        if (array != null) {
          this.ccbDocument = array[0];
          if (array.length > 1) {
            this.aditiveDocument = array[1];
          }
        }
      });
  }

  getDDC() {
    this.contractService
      .getListOfDocuments(this.contract.contractNumber, 'DDC')
      .subscribe((res) => {
        if (res) {
          this.ddcDocument = res;
        }
      });
  }

  get openInstallments() {
    return this.contract?.tranches.filter(installment => installment.situation !== 'L')
  }

  get paidInstallments() {
    return this.contract?.tranches
      .filter(installment => installment.situation === 'L')
      .length;
  }

  get installmentsNumber() {
    return this.contract?.tranches.length;
  }

  setActiveSection(newSection: string) {
    this.activeSection = newSection;
  }

  generatePayment(selectedInstallments: string[], totalValue: number) {
    let body = new Slip(
      this.paymentRequest.agencyCode,
      this.paymentRequest.companyCode,
      this.contract.contractNumber,
      this.user.documentNumber,
      this.paymentRequest.productCode,
      this.paymentRequest.shopkeeperCode,
      this.paymentRequest.storeCode,
      selectedInstallments
    );

    let paymentRequest = new PaymentRequest(
      body.agencyCode,
      body.companyCode,
      body.contractNumber,
      body.documentNumber,
      body.productCode,
      body.shopkeeperCode,
      body.storeCode,
      body.tranchesNumber
    );

    this.contractService._paymentRequest.next(paymentRequest);

    const currentDate = (new Date()).toISOString();
    let payment = new Payment(
      this.contract.contractNumber,
      selectedInstallments.length,
      currentDate,
      totalValue
    );

    this.contractService.setPayments(payment);
    this.router.navigate(['/pagamentos']);
  }

  generateInstallmentPayment(installment: Tranch) {
    this.generatePayment([installment.tranchCode], installment.outstandingBalance);
  }
}
