import { catchError } from 'rxjs/operators';
import { DDCDocumentInformations } from './../../../shared/models/ddcDocumentInformations.model';
import { DialogAlertComponent } from './../../dialog-alert/dialog-alert.component';
import { MatDialog } from '@angular/material/dialog'
import { CCBDocumentInformations } from '../../../shared/models/ccbDocumentInformations.model';
import { ContractStatus } from './../../../shared/models/contract-status.model';
import { ContractService } from '../../../services/contract.service';
import { Component, OnInit } from '@angular/core';
import { Contract } from 'src/app/shared/models/contract.model';
import { ClientService } from 'src/app/services/client.service';
import { Subscription, throwError } from 'rxjs';

@Component({
  selector: 'app-options-of-informations-contract',
  templateUrl: './options-of-informations-contract.component.html',
  styleUrls: ['./options-of-informations-contract.component.scss'],
})
export class OptionsOfInformationsContractComponent
  implements OnInit {
  subscription: Subscription;
  contracts: Contract[] = [];
  actionsOfCCB: CCBDocumentInformations[];
  actionsOfDDC: DDCDocumentInformations;
  contractExpansive = '';

  wait: boolean = false;

  settled = new ContractStatus(
    'Liquidado',
    'assets/images/icon-liquidated.svg',
    'Ícone de liquidado',
    'status-liquidado'
  );

  arrears = new ContractStatus(
    'Em atraso',
    'assets/images/alert.svg',
    'Ícone de em atraso',
    'status-atraso'
  );

  reneg = new ContractStatus(
    'Renegociado',
    'assets/images/reneg.svg',
    'Ícone de reneg',
    'status-reneg'
  );

  active = new ContractStatus(
    'Vigente',
    'assets/images/active.svg',
    'Ícone de ativo',
    'status-ativo'
  );

  constructor(
    private clientService: ClientService,
    public contratoService: ContractService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getListOfContract();
  }

  getListOfContract() {
    this.contratoService.getListOfContract().subscribe((res) => {
      if (res) {
        this.contracts = res;
      }
    });
  }

  parseStatusContract(contract: Contract): ContractStatus {
    switch (contract.situation) {
      case 'L':
        return this.settled;
      case 'A':
        return contract.overdueDay <= 0 ? this.active : this.arrears;
      case 'R':
        return this.reneg;
      default:
        throwError('errou');
        break;
    }
  }

  openAlertDialog() {
    this.openDialog(
      'Não existe dados para este contrato. ',
      'Favor entrar em contato com o SAC pelo número 0800 025 8000.'
    );
  }

  openDialog(title: string, message: string): void {
    this.dialog.open(DialogAlertComponent, {
      data: { title, message },
    });
  }

  cleanAction() {
    this.actionsOfCCB = null;
    this.actionsOfDDC = null;
  }

  getCCB(numContract: string) {
    this.cleanAction();
    this.wait = true;
    this.contractExpansive = numContract;
    this.contratoService
      .getListOfDocuments(numContract, 'CCB')
      .pipe(
        catchError((err) => {
          this.contractExpansive = '';
          return err;
        })
      )
      .subscribe((array) => {
        if (array != null) {
          this.actionsOfCCB = array;
        } else {
          this.contractExpansive = '';
          this.openAlertDialog();
        }
        this.wait = false;
      });
  }

  getDDC(numContract: string) {
    this.cleanAction();
    this.wait = true;
    this.contractExpansive = numContract;
    this.contratoService
      .getListOfDocuments(numContract, 'DDC')
      .pipe(
        catchError((err) => {
          this.contractExpansive = '';
          return err;
        })
      )
      .subscribe((array) => {
        if (array != null) {
          this.actionsOfDDC = array;
        } else {
          this.contractExpansive = '';
          this.openAlertDialog();
        }
        this.wait = false;
      });
  }

  openInNewTab(documentId) {
    this.contratoService.downloadDocument(documentId).subscribe((res: any) => {
      window.open(res.url);
    });
  }
}
