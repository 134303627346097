import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contract-card-skeleton',
  templateUrl: './contract-card-skeleton.component.html'
})
export class ContractCardSkeletonComponent {
  @Input() variant: 'default' | 'small' = 'default';

  cardVariants = {
    'default': 'w-80 rounded-xl',
    'small': 'w-full rounded-lg bg-gray-50'
  }
}
