<div
  class="flex flex-col md:flex-row-reverse items-center justify-center md:p-20 gap-4"
>
  <div class="w-fit">
    <img src="../../../assets/images/Error.png" alt="Error" class="w-44 h-44" />
  </div>
  <div
    class="flex flex-col gap-4 text-center md:text-left md:w-[48%] "
  >
    <app-title class="text-gray-900" [title]="message.title"></app-title>
    <div class="px-5 md:px-0">
      <p
        class="text-gray-900 font-normal text-base text-center md:text-left"
      >
        {{ message.description }}
      </p>
    </div>
    <div class="">
      <app-button
        class="w-full md:w-auto"
        [text]="'Voltar para a página inicial'"
        [routerLink]="message.link"
      ></app-button>
    </div>
  </div>
</div>
