export const listOfInvoiceOptions = [
  {
    descriptionImg: 'Ícone fatura',
    img: '../../../assets/images/icon-registro.svg',
    title: 'Cartão de Crédito PicPay',
    descriptionOption:
      'Solicitar o envio da fatura de cartão de crédito PicPay por e-mail.',
    method: 'CARTAO FATURA PICPAY',
    able: true,
  },
  {
    descriptionImg: 'Ícone fatura',
    img: '../../../assets/images/icon-registro.svg',
    title: 'Cartão de Crédito Original',
    descriptionOption:
      'Solicitar o envio da fatura de cartão de crédito Original por e-mail.',
    method: 'CARTAO FATURA ORIGINAL',
    able: true,
  },
];
