import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Output() closeFunction = new EventEmitter<void>();

  onClose() {
    this.closeFunction.emit();
  }

  onExitModal(event: KeyboardEvent): void {
    this.onKeyDown(event, this.onClose);
  }

  onKeyDown(event: KeyboardEvent, func?: () => void)
    : void {
    if (event.key === 'Enter') {
      event.preventDefault();
      if(func) func();
    }
  }
}
