import { Component } from '@angular/core';

@Component({
  selector: 'app-informations-contract',
  templateUrl: './informations-contract.component.html',
  styleUrls: ['./informations-contract.component.scss']
})
export class InformationsContractComponent {


}
