import { Component } from '@angular/core';

@Component({
  selector: 'app-banner-initial',
  templateUrl: './banner-initial.component.html',
  styleUrls: ['./banner-initial.component.scss']
})
export class BannerInitialComponent  {

}
