
import { Component, Input} from '@angular/core';
import { OptionPayResponse } from 'src/app/shared/models/optionPayResponse.model';

@Component({
  selector: 'app-option-pay',
  templateUrl: './option-pay.component.html',
  styleUrls: ['./option-pay.component.scss']
})
export class OptionPayComponent {

  @Input()
  public option : OptionPayResponse;

}
