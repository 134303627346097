export const partner = [
  {
    name: 'Picpay',
    link: 'https://www.original.com.br/correspondentebancario/',
    image: '../../../assets/images/logo-picpay.svg',
  },
  {
    name: 'InConta',
    link: 'https://www.original.com.br/correspondentebancario/',
    image: '../../../assets/images/logo-inconta.svg',
  },
  {
    name: 'Ame',
    link: 'https://www.original.com.br/correspondentebancario/',
    image: '../../../assets/images/logo-ame.svg',
  },
];
