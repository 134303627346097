<app-loading-wait *ngIf="loading"></app-loading-wait>
<div class="optionconteiner" *ngIf="!loading">
  <div class="container">
    <mat-card
      appearance="outlined"
      [ngClass]="{
        'em-breve': !item.able
      }"
      class="card"
      *ngFor="let item of options"
      (click)="findMethod(item.method)"
    >
      <div class="icon">
        <img [alt]="item.descriptionImg" [src]="item.img" />
      </div>
      <mat-card-title>{{ item.title }}</mat-card-title>
      <mat-card-content class="descricao">
        {{ item.descriptionOption }}
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #embreve>
  <button mat-button class="em-breve">EM BREVE</button>
</ng-template>
