import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { listOfInvoiceOptions } from '../../../shared/models/base-config/listOfOptionsInvoice.config';
import { Router } from '@angular/router';
import { ContractService } from '../../../services/contract.service';
import { OptionPayResponse } from '../../../shared/models/optionPayResponse.model';
import { DialogAlertComponent } from '../../dialog-alert/dialog-alert.component';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-option-invoice-card',
  templateUrl: './option-invoice-card.component.html',
  styleUrls: ['./option-invoice-card.component.scss'],
})
export class InvoiceOptionComponent {
  constructor(
    public dialog: MatDialog,
    public contractService: ContractService,
    public router: Router
  ) {}

  options: Array<OptionPayResponse> = listOfInvoiceOptions;

  isProduction = environment.production;

  openDialog(
    title: string,
    message: string,
    actionYes: string,
    actionNo: string
  ): void {
    this.dialog.open(DialogConfirmComponent, {
      data: { title, message, actionYes, actionNo },
    });
  }

  openAlert(title: string, message: string): void {
    this.dialog.open(DialogAlertComponent, {
      data: { title, message },
    });
  }

  sendInvoicePicPayCard() {
    this.openDialog(
      'Receber fatura no e-mail',
      'Confirma a solicitação de fatura de cartão de crédito PicPay no e-mail cadastrado?',
      'enviaFaturaPicPay',
      'naoEnviaFaturaPicPay'
    );
  }

  sendInvoiceOriginalCard() {
    this.openDialog(
      'Receber fatura no e-mail',
      'Confirma a solicitação de fatura de cartão de crédito Original no e-mail cadastrado?',
      'enviaFaturaOriginal',
      'naoEnviaFaturaOriginal'
    );
  }

  findMethod(item: string) {
    switch (item) {
      case 'CARTAO FATURA PICPAY': {
        this.sendInvoicePicPayCard();
        break;
      }
      case 'CARTAO FATURA ORIGINAL': {
        this.sendInvoiceOriginalCard();
        break;
      }
      default: {
        console.log('Não implementado');
        break;
      }
    }
  }
}
