import { OptionPayResponse } from './../../shared/models/optionPayResponse.model';
import { ContractService } from './../../services/contract.service';
import { Router } from '@angular/router';
import { listOfOptions } from '../../shared/models/base-config/listOfOptionsPay.config';
import { DialogAlertComponent } from './../dialog-alert/dialog-alert.component';
import { DialogConfirmComponent } from './../dialog-confirm/dialog-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { DialogRedirectComponent } from '../dialog-redirect/dialog-redirect.component';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class OptionComponent implements OnInit {
  loading;
  options: Array<OptionPayResponse>;

  constructor(
    public dialog: MatDialog,
    public contractService: ContractService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.getOptions();
  }

  removeItemByMethod(list: Array<OptionPayResponse>, methodToRemove: string): Array<OptionPayResponse> {
    return list.filter(item => item.method !== methodToRemove);
  }

  getOptions(): void {
    this.options = listOfOptions;
  }

  openDialog(
    title: string,
    message: string,
    actionYes: string,
    actionNo: string
  ): void {
    this.dialog.open(DialogConfirmComponent, {
      data: { title, message, actionYes, actionNo },
    });
  }

  openAlert(title: string, message: string): void {
    this.dialog.open(DialogAlertComponent, {
      data: { title, message },
    });
  }

  informationsContract(): void {
    this.router.navigate(['/about']);
  }

  creditReport() {
    this.router.navigate(['/creditreport']);
  }

  goToDDC() {
    this.router.navigate(['/ddc']);
  }

  goToInvoice() {
    this.router.navigate(['/invoicecard']);
  }

  payInstallments() {
    this.router.navigate(['/financialpanel']);
  }

  reneg() {
    this.dialog.open(DialogRedirectComponent);
  }

  findMethod(item: string) {
    switch (item) {
      case 'LISTAR PARCELAS': {
        this.payInstallments();
        break;
      }
      case 'CARTAO FATURA': {
        this.goToInvoice();
        break;
      }
      case 'EXTRATO ANUAL': {
        this.creditReport();
        break;
      }
      case 'CONSULTA INFORMACOES': {
        this.informationsContract();
        break;
      }
      case 'CONSULTA DDC': {
        this.goToDDC();
        break;
      }
      default: {
        console.log('Não implementado');
        break;
      }
    }
  }
}
