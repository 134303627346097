<span class="flex items-center justify-center">
    <input
        type="checkbox"
        [disabled]="disabled"
        [checked]="checked"
        (change)="onCheck($event.target.checked)"
        class="w-[18px] h-[18px] border-2 rounded-[5px] focus:ring-transparent focus:outline-none checked:focus:bg-primary-500 checked:hover:bg-primary-500"
        [class.checked:bg-gray-500]="disabled"
        [class.border-gray-500]="disabled"
        [class.checked:bg-primary-500]="!disabled"
        [class.border-gray-900]="!disabled"
        [class.cursor-pointer]="!disabled"
    />
</span>
