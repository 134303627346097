import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { TimerStateService } from '../../../../services/timer-state.service';

@Component({
  selector: 'app-timer',
  template: `<span #time></span>`
})

export class TimerComponent {
  @Input() timeInSeconds!: number;
  counter: number = 0;
  totalTime: number = 0;
  isTimeOut: boolean = false;
  interval: any;

  @ViewChild('time', { static: false }) timeElement: ElementRef;

  constructor(private timerStateService: TimerStateService) { }

  ngAfterViewInit() {
    let element = this.timeElement.nativeElement;
    this.totalTime = this.timeInSeconds;
  
    this.interval = setInterval(() => {
      let minutos = Math.floor(this.totalTime / 60);
      let segundos = this.totalTime % 60;
  
      let tempoFormatado = `${minutos}:${segundos.toString().padStart(2, '0')}`;
  
      if (element) {
        element.innerHTML = tempoFormatado;
      }
  
      if (this.counter == this.timeInSeconds) {
        clearInterval(this.interval);
        this.isTimeOut = true;
        this.timerStateService.setTimeOutState(true);
        return;
      }
  
      this.counter++;
      this.totalTime--;
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}


