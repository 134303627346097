import { Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { Payment } from 'src/app/shared/models/payment.model';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent  {
  @Input() payment: Observable<Payment>;
  @Input() paymentLoading: true;
}
