import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { ContractService } from 'src/app/services/contract.service';
import { DDCContractService } from 'src/app/services/ddc-contract.service';
import { EmpcContract } from 'src/app/shared/models/empc-contract.model';
import { Subscription, throwError } from 'rxjs';
import { ContractStatus } from 'src/app/shared/models/contract-status.model';
import { Contract } from 'src/app/shared/models/contract.model';


enum CONTRACT_STATUS {
  'A' = 1, // Em atraso
  'A2' = 2, // Á Vencer
  'V' = 3, // Vigente
  'C' = 4, // Cancelado
  'L' = 5, // Pago
  'R' = 6, // Renegociado
}

@Component({
  selector: 'app-ddc-contract-container',
  templateUrl: './ddc-contract-container.component.html',
  styleUrls: ['./ddc-contract-container.component.scss'],
})
export class DdcContractContainerComponent implements OnInit {

  subscription: Subscription;
  contracts: Array<EmpcContract> = [];
  selectedContractIndex: number;
  loading: boolean = false;

  settled = new ContractStatus(
    'Liquidado',
    'assets/images/icon-liquidated.svg',
    'Ícone de liquidado',
    'status-liquidado'
  );

  arrears = new ContractStatus(
    'Em atraso',
    'assets/images/alert.svg',
    'Ícone de em atraso',
    'status-atraso'
  );

  reneg = new ContractStatus(
    'Renegociado',
    'assets/images/reneg.svg',
    'Ícone de reneg',
    'status-reneg'
  );

  active = new ContractStatus(
    'Vigente',
    'assets/images/active.svg',
    'Ícone de ativo',
    'status-ativo'
  );

  constructor(
    private clientService: ClientService,
    private ddcService: DDCContractService,
    private contractService: ContractService
  ) { }

  ngOnInit() {
    this.findContracts();
  }

  setActive(index) {
    this.selectedContractIndex = index;
  }

  findContracts() {
    this.loading = true;
    this.contractService
      .getListOfContract().subscribe((res) => {
        this.contracts = res.filter(it => it.situation != 'C');
      })
      .add(() => {
        this.loading = false;
      });
  }

  getDDC(contractNumber: string) {
    this.ddcService.getBase64(contractNumber).subscribe((res: any) => {
      var binary = atob(res.contract);
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      var blob = new Blob([view], { type: 'application/pdf' });
      var url = URL.createObjectURL(blob);
      window.open(url);
    });
  }

  parseStatusContract(contract: Contract): ContractStatus | null {
    switch (contract.situation) {
      case 'L':
        return this.settled;
      case 'A':
        return contract.overdueDay <= 0 ? this.active : this.arrears;
      case 'R':
        return this.reneg;
      default:
        throwError('errou');
        break;
    }
  }
}
