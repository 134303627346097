<div class="goBackContainer">
    <span class="goBackContainer__link" [routerLink]="['/meus-emprestimos']">Voltar para opções</span>
</div>

<app-loading-wait *ngIf="loading"></app-loading-wait>

<div class="ddcContainerContracts" *ngIf="!loading && contracts.length > 0">
    <div class="ddcContainerContracts__title">Documento Descritivo do Crédito</div>
    <div *ngFor="let contract of contracts; let i = index" class="ddcContainerContracts__item" (click)="setActive(i)">
      <ng-container [ngTemplateOutlet]="statusContract"
                    [ngTemplateOutletContext]="{ data: parseStatusContract(contract) }">
      </ng-container>
      <ng-template #statusContract let-data="data">
        <span class="ddcContainerContracts__item__marker" [ngClass]="{marker__active: selectedContractIndex === i}"></span>
        <div class="ddcContainerContract__item__info">
            <span class="ddcContainerContracts__item__text">
              Contrato {{contract.contractNumber}} | {{ contract.productDescription }} |
            </span>
            <span class="ddcContainerContracts__item__text" [ngClass]="data.cssClass">
              <img [alt]="data.altImg" [src]="data.srcImg" />
              {{ data.text }}
            </span>
            <div *ngIf="selectedContractIndex === i">
                <button (click)="getDDC(contract.contractNumber)">DOWNLOAD</button>
            </div>
        </div>
      </ng-template>
    </div>
</div>

<div class="ddcContainerContracts" *ngIf="!loading && contracts.length === 0">
    <div class="ddcContainerContracts__title">Documento Descritivo do Crédito</div>
    <div class="ddcContainerContracts__alertContainer">
        <img alt="Alerta sem extrato" class="ddcContainerContracts__imgWarning"
            src="../../../assets/images/alert.svg" />
        <p class="ddcContainerContracts__txtWarning">Você não possui extrato disponível.</p>
    </div>
</div>
