import { DialogAlertComponent } from './../dialog-alert/dialog-alert.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-list-options',
  templateUrl: './dialog-list-options.component.html',
  styleUrls: ['./dialog-list-options.component.scss'],
})
export class DialogListOptionsComponent {
  @Output()
  selectPhone: EventEmitter<string> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      receivedList: Array<string>;
      phone: string;
    },
    private dialogRef: MatDialogRef<DialogListOptionsComponent>
  ) { }

  disabled: boolean = true;

  public cancel() {
    this.close(false);
  }

  public close(value) {
    this.dialogRef.close(value);
  }

  openAlert(title: string, message: string): void {
    this.dialog.open(DialogAlertComponent, {
      data: { title, message },
    });
  }

  onSelect(item: string) {
    this.disabled = false;
    this.data.phone = item;
  }
  onConfirm() {
    this.selectPhone.emit(this.data.phone);
    this.cancel();
  }

  centralAlert() {
    this.cancel();
    this.openAlert(
      'Contato não listado',
      'Pedimos que entre em contato com o nosso SAC pelo 0800 025 8000.'
    );
  }
}
