import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root',
})
export class CreditReportService {
  constructor(private http: HttpClient, private clientService: ClientService) {}

  getListOfInforms(): Observable<any> {
    return this.http.get(`${environment.api}/documents/credit-report`);
  }

  getDocument(fileName, documentId): Observable<any> {
    return this.http.get(`${environment.api}/documents/${documentId}`);
  }
}
