export class Utils {
  static get lottieConfig() {
    return {
      path: '../../assets/loading.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true,
    };
  }

  static colorBySituacao(element: any): string {
    switch (element.status) {
      case 'L':
        return '#56B664';
      case 'A':
        return '#F5A623';
      case 'V':
        if (element.overdueDay < 0) return '#757575';
        return '#5281E0';
      default:
        return '';
    }
  }

  static parseNameStatus(element: any) {
    switch (element.status) {
      case 'A':
        return 'Em atraso';
      case 'R':
        return 'Renegociado';
      case 'V':
        if (element.overdueDay < 0) return 'À vencer';
        return 'Vigente';
      case 'L':
        return 'Pago';
      case 'C':
        return 'Cancelado';
      default:
        return '';
    }
  }

  static getInstallments(selection: any, allSeletion: any, parcelas: any[]) {
    let selectedInstallments;
    if (selection.selected.length > 0 && allSeletion.length == 0) {
      selectedInstallments = parcelas.filter((p) => p.checked === true);
    } else {
      selectedInstallments = allSeletion;
    }
    return selectedInstallments;
  }

  static disabledButton(parcelas: any[]) {
    let selectedQuantity = parcelas.filter(
      (p) => p.checked && p.status != 'L'
    ).length;
    let selectedQuantityBill = parcelas.filter(
      (p) => p.type == 'boleto'
    ).length;
    if (selectedQuantity == 0 && selectedQuantityBill == 0) {
      return { buttonIsDisable: true, backgroundColor: '#D6D6D6' };
    } else {
      return { buttonIsDisable: false, backgroundColor: '#01874D' };
    }
  }

  static removeZeroEsquerda(idPortion: string) {
    return Number(idPortion).toString();
  }

  static validatesCpf(cpf: any) {
    const cpfClean = cpf.value.replace(/\D/g, '');

    if (cpfClean && cpfClean.length == 11) {
      let numbers, digits, sum, i, result, equalDigits;
      equalDigits = 1;

      for (i = 0; i < cpfClean.length - 1; i++) {
        if (cpfClean.charAt(i) !== cpfClean.charAt(i + 1)) {
          equalDigits = 0;
          break;
        }
      }

      if (!equalDigits) {
        numbers = cpfClean.substring(0, 9);
        digits = cpfClean.substring(9);
        sum = 0;
        for (i = 10; i > 1; i--) {
          sum += numbers.charAt(10 - i) * i;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(0))) {
          return { cpfNotValid: true };
        }
        numbers = cpfClean.substring(0, 10);
        sum = 0;

        for (i = 11; i > 1; i--) {
          sum += numbers.charAt(11 - i) * i;
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(1))) {
          return { cpfNotValid: true };
        }
        return null;
      } else {
        return { cpfNotValid: true };
      }
    }
    return { cpfNotValid: false };
  }
}

export const arrayRange = (start: number, stop: number, step = 1) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );
