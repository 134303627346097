<section class="flex flex-col md:flex-row gap-6">
    <div class="flex flex-col grow gap-6">
        <h2 class="!text-[28px] !leading-[34px] !font-bold !m-0">Detalhes do contrato</h2>

        <div class="flex flex-col gap-4 rounded-2xl p-6 border border-gray-200 md:max-w-[687px]">
            <span *ngFor="let property of contractProperties" class="flex flex-col items-start sm:flex-row sm:justify-between sm:items-center text-base">
                <p>{{property.label}}</p>
                <p class="!font-bold">{{handlePropertyValue(property.value)}}</p>
            </span>


            <hr class="w-full border-gray-200">

            <span class="flex flex-col items-start sm:flex-row sm:justify-between sm:items-center text-base">
                <p>Início do contrato</p>
                <p class="!font-bold">{{contract.issueDate | brDate}}</p>
            </span>
            <span class="flex flex-col items-start sm:flex-row sm:justify-between sm:items-center text-base">
                <p>Fim do contrato</p>
                <p class="!font-bold">{{contract.dueDate | brDate}}</p>
            </span>
        </div>
    </div>

    <div *ngIf="ccbDocument || aditiveDocument || ddcDocument" class="flex flex-col gap-4">
        <h4 class="!text-xl !font-bold !m-0">Documentos</h4>

        <div class="w-auto flex overflow-x-auto no-scrollbar sm:grid sm:grid-cols-3 md:grid-cols-1 md:grid-rows-3 gap-4">
            <app-interactive-card
            *ngIf="ccbDocument"
            [text]="'Contrato'" [icon]="'file-alt'" (click)="downloadDocument(ccbDocument.documentId)"
            class="h-[106px] min-w-[160px] md:w-[160px]" onKeyPress="handleKeyPress($event)" onKeyDown="handleKeyDown($event)" onKeyUp="handleKeyUp($event)">
            </app-interactive-card>

            <app-interactive-card 
            *ngIf="aditiveDocument"
            [text]="'Aditivo'" [icon]="'file-plus-alt'" (click)="downloadDocument(aditiveDocument.documentId)"
            class="h-[106px] min-w-[160px] md:w-[160px]" onKeyPress="handleKeyPress($event)" onKeyDown="handleKeyDown($event)" onKeyUp="handleKeyUp($event)">
            </app-interactive-card>

            <app-interactive-card 
            *ngIf="ddcDocument"
            [text]="'Documento Descritivo do Crédito'" [icon]="'file-check-alt'" (click)="downloadDDC()"
            class="h-[106px] min-w-[160px] md:w-[160px]" onKeyPress="handleKeyPress($event)" onKeyDown="handleKeyDown($event)" onKeyUp="handleKeyUp($event)">
            </app-interactive-card>
        </div>
    </div>
</section>