import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  animations: [
    trigger('progressAnimation', [
      state('start',
        style({
          width: '0%'
        })),
      state('end',
        style({
          width: '{{progress}}%'
        }),
        { params: { progress: 0 } }),
      transition('start => end', animate('750ms ease-in-out'))
    ])
  ]
})
export class ProgressBarComponent implements OnInit {
  @Input() max: number = 1;
  @Input() value: number = 0;

  loadAnimationState: string = 'start';

  get progress() {
    return this.value / this.max * 100
  }

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.loadAnimationState = 'end';
    }, 100);
  }

}
