<mat-accordion class="section">
  <app-loading-wait *ngIf="!contracts.length"></app-loading-wait>

  <div *ngFor="let contract of contracts">
    <ng-container [ngTemplateOutlet]="statusContract"
      [ngTemplateOutletContext]="{ data: parseStatusContract(contract) }">
    </ng-container>
    <ng-template #statusContract let-data="data">
      <mat-expansion-panel *ngIf="contract.situation != 'C'" [ngClass]="data.cssClass" (closed)="cleanAction()"
        [expanded]="contractExpansive == contract.contractNumber" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title class="titulo">
            Contrato {{ contract.contractNumber }}
            <div>
              <span>
                <span class="titulo hid">| </span>
                {{ contract.productDescription }}
              </span>
            </div>
            <div>
              <span [ngClass]="data.cssClass">
                <span class="titulo hid">| </span>
                <img [alt]="data.altImg" [src]="data.srcImg" />
                {{ data.text }}
              </span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="options">
          <div class="btn" (click)="getCCB(contract.contractNumber)">
            <h3>CCB - Cédula de Crédito Bancária</h3>
          </div>
        </div>
        <app-loading-wait *ngIf="wait"></app-loading-wait>

        <div *ngIf="actionsOfCCB != null">
          <div *ngFor="let action of actionsOfCCB">
            <div class="actionOfDoc" (click)="openInNewTab(action.documentId)">
              <div>
                <p>Arquivo: {{ action.fileName }}</p>
              </div>
              <div>
                <img src="../../../assets/images/icon-pdf.svg" alt="Baixar" />
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="actionsOfDDC != null">
          <div class="actionOfDoc">
            <div>
              <h3>Documento Descritivo do Contrato</h3>
            </div>
            <div>
              <img src="../../../assets/images/icon-pdf.svg" alt="Baixar" />
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </ng-template>
  </div>
</mat-accordion>