import { DDCDocumentInformations } from './../shared/models/ddcDocumentInformations.model';
import { CCBDocumentInformations } from '../shared/models/ccbDocumentInformations.model';
import { BilletBody } from './../shared/models/billetBody.model';
import { DialogAlertComponent } from './../components/dialog-alert/dialog-alert.component';
import { ManifestationsResponse } from './../shared/models/manifestationsResponse.model';
import { ExtractRequested } from '../shared/models/ExtractRequested.model';
import { MatDialog } from '@angular/material/dialog';
import { PixQRCode } from './../shared/models/PixQRCode.model';
import { map, tap, mergeMap, catchError } from 'rxjs/operators';
import { BilletModel } from './../shared/models/billet.model';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentRequest } from '../shared/models/paymentRequest.model';
import { Payment } from '../shared/models/payment.model';
import { Tranch } from '../shared/models/tranch.model';

enum ContractSituation {
  LATE = 'A',
  PAID = 'L',
  ACTIVE = 'V',
  CANCELLED = 'C',
  RENEGOTIATED = 'R',
  ADITED = 'D'
};

const DAYS_TO_MANDATORY_INSTALLMENT = 5;

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  _payment = new BehaviorSubject<Payment>(null);

  _billet = new BehaviorSubject<BilletBody>(null);

  _paymentRequest = new BehaviorSubject<any>(null);

  _paymentPix = new BehaviorSubject<PixQRCode>(null);

  _extract = new BehaviorSubject<ExtractRequested>(null);

  constructor(private readonly http: HttpClient, public dialogAlert: MatDialog) { }

  getListOfContract(): Observable<any> {
    return this.http.get(`${environment.api}/contracts`);
  }

  getInstallmentsOfContract(number): Observable<any> {
    return this.http.get(`${environment.api}/contracts/${number}`);
  }

  getNextInstallments(): Observable<any> {
    return this.http.get(`${environment.api}/next-installments`);
  }

  setPayments(payment: Payment) {
    this._payment.next(payment);
  }

  getPayment(): Observable<Payment> {
    return this._payment;
  }

  getPaymentRequest(): Observable<PaymentRequest> {
    return this._paymentRequest;
  }

  setUpdateBySlips(newDueDate: string, totalValue: number) {
    let newPayment = new Payment(
      this._payment.getValue().contract,
      this._payment.getValue().quantity,
      newDueDate,
      totalValue,
    );
    this.setPayments(newPayment);
  }

  getQrCodePix(): Observable<PixQRCode> {
    return this._paymentPix.asObservable();
  }

  getBillet(): Observable<BilletBody> {
    return this._billet;
  }

  setBillet(value: BilletBody) {
    this._billet.next(value);
  }

  getPix(): Observable<PixQRCode | any> {
    let response: Observable<PixQRCode>;
    const paymentType = 'PIX';

    response = this.getPaymentData(paymentType);
    return response.pipe(
      tap((res) => {
        if (res.status !== 406) {
          this.setUpdateBySlips(
            res.paymentInformation.pix.qrCode.createdAt,
            res.amount,
          );
        }
      }),
    );
  }

  getBilletData(): Observable<BilletModel | any> {
    let response: Observable<BilletModel>;
    const paymentType = 'SLIP';

    response = this.getPaymentData(paymentType);
    return response.pipe(
      tap((data) =>
        this.setBillet(new BilletBody(data.contractId, data, data.installments.length)),
      ),
      tap((data) => {
        this.setUpdateBySlips(
          data.paymentInformation.slip.dueDate,
          Number(data.amount),
        );
      }),
    );
  }

  getPaymentData(paymentType: string): Observable<any> {
    let paymentRequest = this._paymentRequest.getValue();
    let body = {
      paymentType: paymentType,
      productCode: paymentRequest.productCode,
      contractNumber: paymentRequest.contractNumber,
      installments: paymentRequest.listInstallments,
    };

    return this.http.post(`${environment.api}/payments`, body);
  }

  maskEmail(email) {
    const atIndex = email.indexOf('@');
    if (atIndex >= 3) {
      const firstThree = email.substring(0, 3);
      const middlePart = '*'.repeat(atIndex - 3);
      const lastPart = email.substring(atIndex);
      return firstThree + middlePart + lastPart;
    }
    return email;
  }

  formartDate(date: string): string {
    let vari = date.split('-', 3);
    let newDate = vari[2] + '/' + vari[1] + '/' + vari[0];
    return newDate;
  }

  getExtract(): Observable<ExtractRequested> {
    return this.getManifestations().pipe(
      map((res) => {
        this._extract.next(res.data);
        return res.data;
      }),
    );
  }

  getManifestations(): Observable<ManifestationsResponse> {
    return this.http.get<ManifestationsResponse>(
      `${environment.api}/manifestations/data`,
    );
  }

  requestInvoicePicpay() {
    return this.http.get(`${environment.api}/invoice`).subscribe(
      (res) => {
        this.dialogAlert.open(DialogAlertComponent, {
          data: {
            title: 'Pedido foi realizado com sucesso',
            message:
              'Pedido foi realizado com sucesso, em breve você receberá a fatura do cartão de crédito no e-mail cadastrado',
          },
        });
      },
      (error) => {
        this.dialogAlert.open(DialogAlertComponent, {
          data: {
            title: 'Não foi possível identificar a sua última fatura',
            message:
              'Acesse a central de ajuda pelo APP PicPay ou ligue para a Central de Atendimento, telefones: (11) 4003-3939 ou 0800 025 8000.',
          },
        });
      },
    );
  }

  requestInvoiceOriginal() {
    return this.http.post(`${environment.api}/invoice-original`, {}).subscribe(
      (res: any) => {
        this.dialogAlert.open(DialogAlertComponent, {
          data: {
            title: 'Pedido foi realizado com sucesso',
            message:
              'Pedido foi realizado com sucesso, em breve você receberá a fatura do cartão de crédito no e-mail ' +
              this.maskEmail(res.emailAddress),
          },
        });
      },
      (error) => {
        this.dialogAlert.open(DialogAlertComponent, {
          data: {
            title: 'Não foi possível identificar a sua última fatura',
            message:
              'Ligue na central de atendimento nos telefones: (11)4004-0800 (capitais), 0300 777 0800 (demais regiões) ou acesse o APP',
          },
        });
      },
    );
  }

  emitIncomeTaxStatement() {
    let extract = this._extract.getValue();
    this.http
      .post<any>(`${environment.api}/manifestations/extract`, extract)
      .subscribe(
        (res) => {
          if (res.data.message == 'Manifestação ja foi gerada!') {
            this.dialogAlert.open(DialogAlertComponent, {
              data: {
                title: 'Pedido já foi realizado anteriormente',
                message:
                  'Seu pedido já foi realizado anteriormente e em breve o seu extrato estará no seu e-mail. Por gentileza aguarde.',
              },
            });
          } else {
            this.dialogAlert.open(DialogAlertComponent, {
              data: {
                title: 'Pedido foi realizado com sucesso',
                message:
                  'Seu pedido foi realizado com sucesso e em breve o seu extrato estará no seu e-mail',
              },
            });
          }
        },
        (error) => {
          this.dialogAlert.open(DialogAlertComponent, {
            data: {
              title: 'Desculpe, encontramos problemas',
              message:
                'Ops, esta funcionalidade está indisponível no momento. Por favor, tente novamente mais tarde',
            },
          });
        },
      );
  }

  downloadDocument(documentId): Observable<any> {
    return this.http.get<CCBDocumentInformations>(
      `${environment.api}/documents/${documentId}`,
    );
  }

  getListOfDocuments(
    contractNumber: string,
    typeDocument: string,
  ): Observable<any> {
    return this.getInstallmentsOfContract(contractNumber).pipe(
      mergeMap((res) => {
        if (typeDocument == 'CCB') {
          return this.http.get<CCBDocumentInformations>(
            `${environment.api}/documents/contract/${contractNumber}`,
          );
        } else {
          return this.http.get<DDCDocumentInformations>(
            `${environment.api}/documents/contract/${contractNumber}/ddc`,
          );
        }
      }),
      catchError((error) => {
        this.dialogAlert.open(DialogAlertComponent, {
          data: {
            title: 'Serviço indisponível no momento.',
            message: 'Tente novamente mais tarde',
          },
        });
        return error;
      }),
    );
  }

  isMandatoryForAdvance(installment: any) {
    const currentDate = new Date();
    const dueDate = new Date(installment.dueDate);
    const timeDifference = dueDate.getTime() - currentDate.getTime();
    const daysToDueDate = Math.round(timeDifference / (1000 * 3600 * 24));

    return installment.situation === ContractSituation.LATE ||
      (installment.situation === ContractSituation.ACTIVE && daysToDueDate < DAYS_TO_MANDATORY_INSTALLMENT);
  }
}
