<mat-accordion>
  <app-loading-wait *ngIf="!contracts.length"></app-loading-wait>

  <div *ngFor="let contract of contracts">
    <ng-container
      [ngTemplateOutlet]="statusContract"
      [ngTemplateOutletContext]="{
        data: parseStatusContract(contract)
      }"
    >
    </ng-container>
    <ng-template #statusContract let-data="data">
      <mat-expansion-panel
        *ngIf="contract.situation != 'C'"
        [ngClass]="
          contract.overdueDay > 0 || contract.situation != 'A'
            ? data?.cssClass
            : ''
        "
        (closed)="closePanel()"
        (opened)="getInstallments(contract.contractNumber)"
        hideToggle
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="titulo">
            Contrato {{ contract.contractNumber }}
            <div>
              <span>
                <span class="titulo hid"> | </span>
                {{ contract.productDescription }}
              </span>
            </div>
            <div>
              <span [ngClass]="data?.cssClass">
                <span class="titulo hid"> | </span>
                <img [alt]="data?.altImg" [src]="data?.srcImg" />
                {{ data?.text }}
              </span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-loading-wait *ngIf="!installments.length"></app-loading-wait>

        <div *ngIf="installments.length">
          <div class="info" *ngIf="isConsigned()">
            <img alt="informativo" src="../../../assets/images/icon-info.svg" />
            <span
              >Ao antecipar parcelas, caso o convênio tenha realizado o débito
              em folha e ainda não repassado ao PicPay Bank, estornaremos o
              recebimento em duplicidade em até 5 dias úteis após o vencimento.
            </span>
          </div>
          <section class="view-desktop">
            <table
              mat-table
              [dataSource]="installments"
              class="mat-elevation-z8"
            >
              <ng-container matColumnDef="selecao">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    [disabled]="getIfStatusContractEnd(contract)"
                    [(ngModel)]="this.check_all"
                    (change)="changeAll()"
                  >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    [disabled]="!row.enableable"
                    [(ngModel)]="row.checked"
                    (change)="changeCheck($event, row)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="parcela">
                <th mat-header-cell *matHeaderCellDef>Parcelas</th>
                <td mat-cell *matCellDef="let element">
                  {{ removeZeroLeft(element.tranchCode) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Valor da parcela</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.installmentAmount | currency : "BRL" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="outstandingPrincipalBalance">
                <th mat-header-cell *matHeaderCellDef>
                  Valor atualizado
                  <button (click)="openAlertDialog()" class="valorAttButton">
                    ?
                  </button>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.outstandingBalance | currency : "BRL" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="dueDate">
                <th mat-header-cell *matHeaderCellDef>Data de vencimento</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dueDate | date : "dd/MM/yyyy" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Situação</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  [style.color]="colorBySituacao(element)"
                >
                  {{ parseNameStatus(element) }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </section>

          <section class="view-mobile">
            <mat-card appearance="outlined" *ngFor="let parcela of installments">
              <mat-checkbox
                (change)="changeCheck($event, parcela)"
                [disabled]="!parcela.enableable"
                [(ngModel)]="parcela.checked"
              >
              </mat-checkbox>

              <mat-card-content class="columm-left">
                <mat-card-subtitle>
                  Parcela
                  {{ removeZeroLeft(parcela.tranchCode) }}ª
                </mat-card-subtitle>
                <mat-card-subtitle>Situação </mat-card-subtitle>
                <mat-card-subtitle>Valor da parcela</mat-card-subtitle>
                <mat-card-subtitle>
                  Valor atualizado
                  <button (click)="openAlertDialog()" class="valorAttButton">
                    ?
                  </button>
                </mat-card-subtitle>
                <mat-card-subtitle> Data de vencimento </mat-card-subtitle>
              </mat-card-content>

              <mat-card-content class="columm-right">
                <mat-card-subtitle [style.color]="colorBySituacao(parcela)">
                  {{ parseNameStatus(parcela) }}
                </mat-card-subtitle>
                <mat-card-subtitle>
                  {{ parcela.installmentAmount | currency : "BRL" }}
                </mat-card-subtitle>
                <mat-card-subtitle>
                  {{ parcela.outstandingBalance | currency : "BRL" }}
                </mat-card-subtitle>
                <mat-card-subtitle>
                  {{ parcela.dueDate | date : "dd/MM/yyyy" }}
                </mat-card-subtitle>
              </mat-card-content>
            </mat-card>
          </section>

          <div class="total-selection">
            <p>
              Valor Total:
              <strong>
                {{ totalSelectedInstallments | currency : "BRL" }}
              </strong>
            </p>
          </div>
        </div>

        <div class="container-btn" *ngIf="totalSelectedInstallments != 0">
          <button
            type="submit"
            [disabled]="totalSelectedInstallments == 0"
            class="btn-gerar"
            (click)="paymentValidation(contract.contractNumber)"
            mat-raised-button
          >
            CONTINUAR
          </button>
        </div>
      </mat-expansion-panel>
    </ng-template>
  </div>
</mat-accordion>
