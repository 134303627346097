<section class="flex flex-col gap-6">
    <h2 class="!text-[28px] !leading-[34px] !font-bold !m-0">Canais de atendimento</h2>

    <div class="w-full flex flex-col md:flex-row gap-6">
        <div *ngFor="let channel of channels" class="flex flex-col gap-4 grow">
            <span class="flex gap-4 items-center">
                <app-icon [icon]="channel.icon" class="w-10 h-10 p-3 fill-gray-900 bg-gray-50 rounded-full"></app-icon>
                <h4 class="!text-xl !font-bold !m-0">{{channel.title}}</h4>
            </span>

            <a class="flex items-center p-2" [href]="channel.url" target="_blank" rel="noopener" [class.cursor-default]="!channel.url">
                <div class="flex flex-col grow">
                    <p class="!text-base !font-bold">
                        {{channel.message}}
                    </p>
                    <p class="!text-xs">{{channel.supportText}}</p>
                </div>
                <app-icon [icon]="'chevron-right'" class="w-8 h-8 fill-gray-900"
                    [class.hidden]="!channel.url"></app-icon>
            </a>
        </div>
    </div>
</section>