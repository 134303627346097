<section class="section-five">
    <h1>Não tem conta no Banco Original?</h1>
    <p>Baixe agora mesmo o app e <strong>abra sua conta =)</strong></p>
    <div class="icon-plataforms">
        <a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=br.com.original.bank&hl=pt_BR" target="_blank">
            <img alt="Ícone android" src="assets/images/icon-android.svg">
        </a>
        <a rel="noopener noreferrer" href="https://apps.apple.com/br/app/original-pix-e-conta-digital/id980234079" target="_blank">
            <img alt="Ícone apple" src="assets/images/icon-apple.svg">
        </a>
    </div>
    <div class="footer-section">
        <img alt="Ícone original" src="assets/images/icon-smile.svg">
        <a rel="noopener noreferrer" href="https://www.original.com.br" target="_blank">Conheça o Banco Original</a>
    </div>
</section>