import { PartnerResponse } from './../../shared/models/PartnerResponse.model';
import { partner } from '../../shared/models/base-config/partner.config';
import { Component} from '@angular/core';

@Component({
  selector: 'app-presentation-partner',
  templateUrl: './presentation-partner.component.html',
  styleUrls: ['./presentation-partner.component.scss']
})
export class PresentationPartnerComponent {

  public partners : Array<PartnerResponse> = partner;
}
