import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-alert',
  templateUrl: './dialog-alert.component.html',
  styleUrls: ['./dialog-alert.component.scss']
})
export class DialogAlertComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    message: string,
    title: string
  }, private dialogRef: MatDialogRef<DialogAlertComponent>, public dialog: MatDialog) { }

  public cancel() {
    this.close(false);
  }

  public close(value) {
    this.dialogRef.close(value);
  }
}
