<mat-accordion class="section">
  <app-loading-wait
    class="list-credit-spinner"
    *ngIf="waitList"
  ></app-loading-wait>

  <div *ngFor="let inform of informs">
    <mat-expansion-panel class="status-liquidado" [expanded]="false" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="titulo">
          {{ inform.businessCode.split(":")[1] }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="options">
        <div class="download">
          <div
            class="btn"
            (click)="openInNewTab(inform.fileName, inform.documentId)"
          >
            <h3>Download</h3>
          </div>

          <app-loading-wait
            class="credit-spinner"
            *ngIf="waitDownload"
          ></app-loading-wait>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
  <div
    class="without-reports"
    *ngIf="(!informs || informs.length == 0) && !waitList"
  >
    Você não possui informes de Crédito
  </div>
</mat-accordion>
