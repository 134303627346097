<section class="flex flex-col gap-4 w-full">
  <div class="flex flex-col p-6 border rounded-xl gap-6">
    <h3 class="!text-xl !font-bold !m-0 text-gray-900">Pix Copia e Cola</h3>

    <app-skeleton
      *ngIf="paymentLoading; else paymentByPixLoaded"
      class="w-full h-10 flex rounded overflow-hidden"
    ></app-skeleton>

    <ng-template #paymentByPixLoaded class="w-full h-10 flex rounded">
      <div class="flex flex-col justify-between items-center">
        <div class="flex flex-col justify-between items-center gap-6 size-full">
          <div class="border rounded-lg border-gray-200">
            <img
              alt="QrCode"
              class="min-w-40 max-w-36 rounded-lg"
              [src]="this.getPixQRCode()"
            />
          </div>
          <div class="flex h-full flex-col justify-between size-full">
            <div
              class="flex rounded-xl leading-[1.75rem] p-3 bg-gray-50 flex-grow"
            >
              <p class="flex truncate min-w-0">
                {{ this.getPixText() }}
              </p>
              <button class="pl-3" (click)="copyPixText()">
                <img
                  alt="Ícone de cópia"
                  class="min-w-4 max-w-4 fill-primary-500"
                  src="../../../assets/images/icon-copy-2.svg"
                />
              </button>
            </div>
            <div class="text-start flex items-center py-2 px-1 gap-1">
              <app-icon
                [icon]="'feedback-info-mono'"
                class="w-4 block fill-neutral-500"
              ></app-icon>
              <p class="text-xs leading-5 font-normal text-gray-900">
                Compensação imediata.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</section>
