<div class="dialog">
  <div class="header">
    <h1 matDialogTitle>{{ data.title }}</h1>
    <mat-dialog-actions>
      <button mat-button mat-dialog-close class="matDialogClose">
        Fechar
        <img alt="Ícone Fechar" src="../../../assets/images/icon-close.svg" />
      </button>
    </mat-dialog-actions>
  </div>
  <hr />
  <mat-dialog-content>
    {{ data.message }}
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-button (click)="caseNo()">Não</button>
    <button mat-button (click)="caseYes()" cdkFocusInitial>Sim</button>
  </div>
</div>