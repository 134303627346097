import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents-credit-report',
  templateUrl: './documents-credit-report.component.html',
  styleUrls: ['./documents-credit-report.component.scss']
})
export class DocumentsCreditReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
