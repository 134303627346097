import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-redirect',
  templateUrl: './dialog-redirect.component.html',
  styleUrls: ['./dialog-redirect.component.scss'],
})
export class DialogRedirectComponent {
  constructor(
    public dialogAlert: MatDialog,
    private dialogRef: MatDialogRef<DialogRedirectComponent>
  ) {}
}
