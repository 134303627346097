<section class="disponivel-portal">
  <div class="opcao-disponivel">
    <h1>Veja as opções de pagamento disponíveis para você</h1>
    <p>
      Com a nossa plataforma online é rapidinho, se você possui um cartão de
      crédito ou crédito pessoal contratado, nós podemos te ajudar a ficar
      sempre em dia!
    </p>
  </div>

  <div class="container-options">
    <app-option-pay *ngFor="let option of options" [option]="option" class="opcao"></app-option-pay>
  </div>
</section>
