import { Component } from '@angular/core';

@Component({
  selector: 'app-about-contract',
  templateUrl: './about-contract.component.html'
})
export class AboutContractComponent {


}
