import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-credit-card',
  templateUrl: './invoice-card.component.html',
})
export class InvoiceCreditCardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
