import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-split-screen',
  templateUrl: './split-screen-layout.component.html',
  styleUrls: ['./split-screen-layout.component.scss'],
})
export class SplitScreenLayoutComponent {
  @Input() img: { path: string; description: string };
}
