<app-center-column-layout>
    <app-title [title]="'Meus empréstimos'"></app-title>

    <div class="w-full relative">
        <div *ngIf="!loadingNextInstallments; else cardsSkeleton" class="flex gap-4 overflow-x-auto no-scrollbar scroll-smooth" id="cardsContainer">
            <div *ngFor="let installment of nextInstallments">
                <app-contract-card [installment]="installment" [paymentFunc]="generatePayment.bind(this)"></app-contract-card>
            </div>
        </div>

        <ng-template #cardsSkeleton>
            <div class="flex gap-4 overflow-x-auto no-scrollbar">
                <app-contract-card-skeleton></app-contract-card-skeleton>
                <app-contract-card-skeleton></app-contract-card-skeleton>
                <app-contract-card-skeleton></app-contract-card-skeleton>
            </div>
        </ng-template>

        <button *ngIf="nextInstallments.length > 3"
            class="hidden lg:flex items-center justify-center bg-white w-10 h-10 rounded-full border shadow-soft absolute -right-5 top-12 z-10"
            (click)="scrollToNext()">
            <app-icon [icon]="'chevron-right'" class="w-6 h-6 fill-gray-500"></app-icon>
        </button>
    </div>

    <section class="flex flex-col gap-6">
        <h2 class="!text-[28px] !leading-[34px] !font-bold !m-0 text-gray-900">Contratos</h2>

        <app-chip-group *ngIf="hasActiveAndFinalizedContracts()">
            <app-chip [value]="'active'" [label]="'Ativos'" (selected)="selectChip($event)"></app-chip>
            <app-chip [value]="'finalized'" [label]="'Finalizados'" (selected)="selectChip($event)"></app-chip>
        </app-chip-group>

        <div class="border border-gray-200 rounded-xl overflow-hidden">
            <table *ngIf="!loadingContractList; else tableSkeleton" class="w-full table-auto">
                <th class="hidden"></th>
                <tr *ngFor="let contract of filteredContracts" class="w-full odd:bg-white even:bg-gray-50 hover:bg-gray-100">
                    <div (click)="goToContract(contract.contractNumber)" (keydown)="onClickContract($event, contract.contractNumber)" class="w-full flex flex-col md:flex-row p-4 gap-2 md:gap-4 items-center justify-start cursor-pointer">
                        <div class="w-full md:w-auto flex grow items-center justify-between">
                            <td class="text-[22px] leading-[30px] font-bold text-primary-700">
                                {{contract.productDescription}}
                            </td>
                            <td class="flex md:hidden">
                                <app-icon [icon]="'chevron-right'" class="w-10 h-10 fill-gray-500"></app-icon>
                            </td>
                        </div>

                        <div class="w-full md:w-auto flex justify-start">
                            <td class="w-[160px] !space-y-1 text-gray-900">
                                <p class="text-sm">Valor contratado</p>
                                <p class="text-lg font-bold">{{contract.releasedValue | currency : "BRL"}}</p>
                            </td>
                            <td class="w-[160px] !space-y-1 text-gray-900">
                                <p class="text-sm">Contratado em</p>
                                <p class="text-lg font-bold">{{ contract.issueDate | brDate }}</p>
                            </td>
                        </div>

                        <td class="hidden md:flex">
                            <app-icon [icon]="'chevron-right'" class="w-10 h-10 fill-gray-500"></app-icon>
                        </td>
                    </div>
                </tr>
            </table>

            <ng-template #tableSkeleton>
                <table class="w-full table-auto">
                    <th class="hidden"></th>
                    <tr *ngFor="let item of [1,2,3]" class="w-full odd:bg-white even:bg-gray-50">
                        <div class="w-full flex flex-col md:flex-row p-4 gap-2 md:gap-4 items-center justify-start">
                            <div class="w-full md:w-auto flex grow items-center justify-between">
                                <app-skeleton class="w-full h-7 block rounded-md overflow-hidden"></app-skeleton>

                                <td class="flex md:hidden">
                                    <app-icon [icon]="'chevron-right'" class="w-10 h-10 fill-gray-500"></app-icon>
                                </td>
                            </div>
    
                            <div class="w-full md:w-auto flex justify-start gap-6">
                                <td class="w-[140px] !space-y-2">
                                    <app-skeleton class="w-full h-5 block rounded overflow-hidden"></app-skeleton>
                                    <app-skeleton class="w-full h-7 block rounded overflow-hidden"></app-skeleton>
                                </td>
                                <td class="w-[140px] !space-y-2">
                                    <app-skeleton class="w-full h-5 block rounded overflow-hidden"></app-skeleton>
                                    <app-skeleton class="w-full h-7 block rounded overflow-hidden"></app-skeleton>
                                </td>
                            </div>
    
                            <td class="hidden md:flex">
                                <app-icon [icon]="'chevron-right'" class="w-10 h-10 fill-gray-500"></app-icon>
                            </td>
                        </div>
                    </tr>
                </table>
            </ng-template>
        </div>
    </section>
</app-center-column-layout>