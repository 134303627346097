<section>
  <div class="flex flex-col gap-6 w-full p-6 border rounded-xl">
    <h3 class="!text-xl !font-bold !m-0 text-gray-900">
      Detalhes do pagamento
    </h3>

    <div class="flex justify-between items-center">
      <ul
        class="w-full flex flex-col gap-2"
        *ngIf="paymentLoading; else paymentByPixLoaded"
      >
        <li class="flex justify-between">
          <app-skeleton
            class="w-full h-6 flex rounded overflow-hidden"
          ></app-skeleton>
        </li>
        <li class="flex justify-between">
          <app-skeleton
            class="w-full h-6 flex rounded overflow-hidden"
          ></app-skeleton>
        </li>
        <li class="flex justify-between">
          <app-skeleton
            class="w-full h-6 flex rounded overflow-hidden"
          ></app-skeleton>
        </li>
      </ul>
      <ng-template #paymentByPixLoaded>
        <ul class="w-full flex flex-col gap-2">
          <li class="flex justify-between">
            <p class="font-normal text-gray-900">Valor a pagar</p>
            <strong class="text-gray-900">
              {{ (payment | async)?.totalValue | currency : 'BRL' }}</strong
            >
          </li>
          <li class="flex justify-between">
            <p class="font-normal text-gray-900">Vence em</p>
            <strong class="text-gray-900">
              {{ (payment | async)?.dueDate | brDate }}</strong
            >
          </li>
          <li class="flex justify-between">
            <p class="font-normal text-gray-900">Antecipação</p>
            <strong class="text-gray-900"
            >{{ (payment | async)?.quantity }}
            {{
              (payment | async)?.quantity === 1 ? 'parcela' : 'parcelas'
            }}</strong
          >
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
</section>
