import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html'
})
export class ChipComponent {
  @Input() value!: string;
  @Input() label!: string;
  @Input() isSelected = false;
  @Output() selected = new EventEmitter<string>();

  selectChip() {
    this.selected.emit(this.value);
  }
}
