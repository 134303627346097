import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ddc',
  templateUrl: './ddc.component.html',
})
export class DDCComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
