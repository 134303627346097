<div class="relative">
  <button class="flex items-center gap-2" (click)="toggleMenu()">
    <app-icon
      [icon]="'chevron-right'"
      class="w-10 h-10 p-2 fill-gray-800"
      [class.rotate-90]="!menuOpened"
      [class.-rotate-90]="menuOpened"
    ></app-icon>
    <div
      class="h-10 w-10 rounded-full bg-[#9E7365] text-white text-lg flex items-center justify-center"
    >
      {{ user.name?.charAt(0) }}
    </div>
  </button>
  <div
    *ngIf="menuOpened"
    class="absolute right-0 top-12 w-72 rounded-xl rounded-tr-none bg-white shadow-soft text-base text-gray-900 flex flex-col z-20 border border-gray-100 p-2 gap-4"
  >
    <span *ngIf="user.name" class="flex gap-4 rounded-xl bg-gray-50 w-full p-4 items-center">
      <div
        class="h-12 w-12 rounded-full bg-[#9E7365] text-white text-2xl flex items-center justify-center"
      >
        {{ user.name?.charAt(0) }}
      </div>
      <p class="text-base font-bold text-gray-900">{{ user.name }}</p>
    </span>

    <div class="flex flex-col w-full rounded-md overflow-hidden">
      <span
        tabindex="0"
        role="button"
        aria-pressed="false"
        class="w-full flex items-center gap-2 hover:bg-gray-50 cursor-pointer py-1"
        (click)="downloadDocument()"
        (keydown)="onReport($event)"
      >
        <app-icon
          [icon]="'receipt'"
          class="w-10 h-10 p-2 fill-gray-500"
        ></app-icon>
        Extrato anual
      </span>
      <a href="https://meajuda.picpay.com/hc/pt-br" target="_blank" rel="noopener">
        <span
          class="w-full flex items-center gap-2 hover:bg-gray-50 cursor-pointer py-1"
        >
          <app-icon
            [icon]="'feedback-info-mono'"
            class="w-10 h-10 p-2 fill-gray-500"
          ></app-icon>
          Ajuda
        </span>
      </a>
      <span
        tabindex="0"
        role="button"
        aria-pressed="false"
        (click)="logout()"
        (keydown)="onLogout($event)"
      >
        <div
          class="w-full flex items-center gap-2 hover:bg-gray-50 cursor-pointer py-1"
          *ngIf="!loadingBtn; else loading"
        >
          <app-icon
            [icon]="'sign-out-alt'"
            class="w-10 h-10 p-2 fill-gray-500"
          ></app-icon>
          Sair
        </div>

        <ng-template #loading>
          <div class="w-full flex items-center justify-center bg-gray-50 py-1 h-12">
            <mat-spinner color="primary" [diameter]="20"></mat-spinner>
          </div>
        </ng-template>
      </span>
    </div>
  </div>
</div>
