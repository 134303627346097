<div class="text-footer">
  <img alt="logo-original" src="../../../assets/images/logo-original.svg" />
  <p>
    A abertura de contas e a concessão de limites de créditos estão sujeitas a
    análise e aprovação.
  </p>
  <p>
    Saiba mais em
    <a rel="noopener noreferrer" href="https://www.original.com.br/documentosimportantes" target="_blank">
      www.original.com.br/documentosimportantes.
    </a>
  </p>
</div>
