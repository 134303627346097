import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router';
import { CreditReportService } from 'src/app/services/creditReport.service';
import { CreditReport } from 'src/app/shared/models/creditReport';
import { DialogAlertComponent } from '../../dialog-alert/dialog-alert.component';
import { ClientService } from 'src/app/services/client.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-options-of-credit-report',
  templateUrl: './options-of-credit-report.component.html',
  styleUrls: ['./options-of-credit-report.component.scss'],
})
export class OptionsOfCreditReportComponent implements OnInit {

  subscription: Subscription;
  documentNumber: string;
  waitList = false;
  waitDownload = false;
  informs: CreditReport[];

  constructor(
    private clientService: ClientService,
    public informeService: CreditReportService,
    public dialog: MatDialog,
    public router: Router) { }

  ngOnInit() {
    this.getCreditReport();
  }

  openInNewTab(fileName, documentId) {
    this.waitDownload = true;
    this.informeService.getDocument(fileName, documentId).subscribe((res) => {
      if (res) {
        window.open(res.url, '_blank');
      } else {
        this.openDialog('Erro no download', 'Ocorreu um erro ao fazer o download do informe de crédito');
      }
      this.waitDownload = false;
    });
  }

  openDialog(title: string, message: string): void {
    this.dialog.open(DialogAlertComponent, {
      data: { title, message },
    });
  }

  getCreditReport(): void {
    this.waitList = true;
    this.informeService.getListOfInforms().subscribe(
      (res) => {
        if (res) {
          this.informs = res;
        }
        this.waitList = false;
      },
      (error) => {
        this.openDialog(
          'Erro no informe de crédito',
          'Ocorreu um erro ao listar os informe de crédito'
        );
        this.waitList = false;
      }
    );
  }
}
