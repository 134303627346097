<section class="section">
  <div class="steps-container">
    <h1>Gerencie seus contratos de forma simples</h1>
    <div class="grid-step">
      <div class="steps">
        <img alt="Ícone CPF" src="../../../assets/images/icon-opcao2.svg" />
        <div class="text-steps">
          <h2>Acompanhe a evolução dos seus contratos</h2>
          <p>
            Consulte parcelas pagas, a vencer ou em atraso, confira históricos e documentações dos seus contratos de empréstimo.
          </p>
        </div>
      </div>
      <div class="steps">
        <img
          alt="Ícone Produtos"
          src="../../../assets/images/icon-financas2.svg"
        />
        <div class="text-steps">
          <h2>Faça pagamentos</h2>
          <p>
            Pague as parcelas dos seus empréstimos via Pix ou boleto.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

