<section>
  <div class="flex flex-col gap-4 w-full">
    <div class="flex flex-col p-6 border rounded-xl gap-6">
      <h3 class="!text-xl !font-bold !m-0 text-gray-900">Boleto</h3>
      <p class="leading-5 font-normal text-gray-900">
        Lembre-se que seu boleto possui senha de acesso. Ela é composta pelos 5
        primeiros dígitos do seu CPF.
      </p>

      <app-skeleton
        *ngIf="paymentLoading; else paymentByBilletLoaded"
        class="w-full h-10 flex rounded overflow-hidden"
      ></app-skeleton>

      <ng-template #paymentByBilletLoaded>
        <div class="flex flex-col justify-between items-center">
          <div class="flex w-full flex-col justify-between items-center gap-6">
            <div class="flex flex-col w-full">
              <div
                class="flex w-full rounded-xl leading-[1.75rem] p-3 bg-gray-50"
              >
                <p class="truncate max-w-full">
                  {{ this.getDigitableLine() }}
                </p>
                <button class="pl-3" (click)="copyText()">
                  <img
                    alt="Ícone de cópia"
                    class="min-w-4 max-w-4 fill-primary-500"
                    src="../../../assets/images/icon-copy-2.svg"
                  />
                </button>
              </div>
              <div class="text-start flex items-center py-2 px-1 gap-1">
                <app-icon
                  [icon]="'feedback-info-mono'"
                  class="w-4 block fill-neutral-500"
                ></app-icon>
                <p class="text-xs leading-5 font-normal text-gray-900">
                  Compensado em até 3 dias úteis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <app-button
      class="w-full"
      [class]="'h-auto py-[14px]'"
      [text]="'Baixar boleto'"
      [loading]="loadingBtn || paymentLoading"
      (click)="downloadPdf()"
      onKeyPress="downloadPdf()"
      tabindex="0"
    ></app-button>
  </div>
</section>
