<mat-toolbar
  [ngStyle]="{ 'background-color': backgroundColor, position: position }"
  class="header-home"
  [ngClass]="{ logada: logged }"
>
  <div *ngIf="logged" class="sup-cell">
    <span> </span>
  </div>
  <div>
    <img
      mat-card-image
      src="../../../assets/images/logo-picpay.svg"
      alt="Logo PicPay"
      [routerLink]="['/meus-emprestimos']"
      class="logo-picpay"
    />
  </div>
  <div *ngIf="logged" class="logout" (click)="logout()">
    <span>Sair</span>
    <img class="icon" src="../../../assets/images/Icon-exit.svg" alt="Sair" />
  </div>
</mat-toolbar>
