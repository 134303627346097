import { OptionPayResponse } from './../../shared/models/optionPayResponse.model';
import { listOfOptions } from '../../shared/models/base-config/listOfOptionsPay.config';
import { Component } from '@angular/core';

@Component({
  selector: 'app-portal-options',
  templateUrl: './portal-options.component.html',
  styleUrls: ['./portal-options.component.scss'],
})
export class PortalOptionsComponent {
  public options: Array<OptionPayResponse> = listOfOptions;
}
