export const environment = {
  production: true,
  clientId: 'picpay-token',
  api: 'https://api.picpay.com/loan-nc-proposal-bff-lp',
  apiAuth: 'https://api.picpay.com/loan-nc-auth-lp',
  recaptchSiteKey: '6LdqjQEqAAAAAKYmbBbYKop_CEXrMQAAXZMgzNAK',
  unavailablePixProducts: ['004006', '004040', '004043', '034043'],
  consigned: {
    productIds: ['004060', '004061', '004070']
  },
  fgts: {
    productIds: ['004040', '004043', '034043'],
    daysToBirthdayMonth: 21,
  },
  apiPicPay: '',
  unavailableOptions: ['HISTORICO DOCUMENTOS']
};
