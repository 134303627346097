import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent {
  @Input() text!: string;
  @Input() variant: 'default' | 'error' = 'default';
  @Input() type: 'default' | 'submit' = 'default';
  @Input() class: string = '';
  @Input() loading: boolean = false;

  buttonVariants ={
    'default': 'bg-primary-500',
    'error': 'bg-critical',
    'loading': 'bg-gray-200'
  }

  buttonTypes = {
    'default': 'button',
    'submit': 'submit'
  }
}
