<section class="container-bannner">
  <div class="text-banner">
    <h1 class="font" style="font-family: 'proxima-nova', sans-serif;">
      Acompanhe seus contratos
    </h1>
    <p>
      Aqui você consegue fazer pagamentos e consultas dos seus contratos de 
      Crédito Pessoal, PicPay Parcela, Empréstimo FGTS e Empréstimo Consignado 
      do Picpay.
    </p>
    <p>
      Para acessar, basta preencher seu CPF e digitar o código que você vai receber via SMS.
    </p>
  </div>
</section>
